import i18n from '@/libs/i18n';
import Axios from '../../libs/strataIns';

export default {
    state: {
        services: [],
        showService: null
    },

    getters: {
        services: (state) => state.services,
        showService: (state) => state.showService
    },

    mutations: {
        setServices(state, payload) {
            state.services = payload;
        },
        addService(state, payload) {
            state.services.push(payload);
        },
        updateService(state, payload) {
            let index = state.services.findIndex(el => el.id == payload.id);
            state.services.splice(index, 1, payload);
        },
        removeService(state, payload) {
            let index = state.services.findIndex(el => el.id == payload);
            state.services.splice(index, 1);
        },
        showService(state, payload) {
            state.showService = payload;
        }

    },
    actions: {
        loadServices({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post("/admin/services", {
                    ...payload
                }, { headers: { "Content-Language": i18n.locale } }).then(res => {
                    if (res.status == 200) {
                        commit("setServices", res.data.results.data);
                        resolve(res);
                    }
                }).catch(rej => {
                    reject(rej);
                });
            });
        },
        loadShowedService({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/admin/services/show/${payload}`).then(res => {
                    if (res.status == 200) {
                        commit("showService", res.data.results);
                        resolve(res);
                    }
                }).catch(rej => {
                    reject(rej);
                });
            });
        },
        removeService({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/admin/services/delete/${payload}`).then(res => {
                    if (res.status == 200) {
                        commit("removeService", payload);
                        resolve(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        updateService({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/admin/services/save`, payload).then(res => {
                    if (res.status == 200) {
                        resolve(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        createService({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/admin/services/save`, {
                    id: payload.id,
                    titles: payload.titles,
                    descriptions: payload.descriptions,
                    data: payload.data,
                    ord: payload.ord,
                    status: payload.status,
                    image_base64: payload.image_base64,
                }).then(res => {
                    if (res.status == 200) {
                        resolve(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },

    }
};