<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t($route.name) }}</b-card-title>
      <b-button
        variant="primary"
        @click="openModal(null, 'Create', 'Create new section', filterSections)"
        v-if="resolvePermission('sections.create')"
      >
        <feather-icon icon="PlusIcon" size="15"> </feather-icon>
        {{ $t("add new") }}
      </b-button>
    </b-card-header>
    <b-row class="mx-1">
      <b-col cols="6">
        <b-form-group :label="$t('Search')" label-for="basicInput">
          <b-form-input id="basicInput" v-model="searchText" />
        </b-form-group>
      </b-col>
      <b-col cols="5">
        <b-form-group :label="$t('filterByPage')" label-for="basicInput">
          <v-select
            :options="getPagesList"
            v-model="selectedPage"
            @input="filterSections"
            :loading="pagesLoader"
            @search="searchPages"
            clearable
          >
          </v-select>
        </b-form-group>
      </b-col>
      <b-col class="col-2 col-md-1 align-self-center">
        <b-button
          variant="primary"
          class="btn my-auto"
          :disabled="getLoading"
          @click="filterSections"
        >
          <feather-icon icon="SearchIcon" size="16"></feather-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-table responsive :items="showedSections" :fields="fields" class="mb-0">
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        <p class="text-nowrap">
          {{ data.value }}
        </p>
      </template>
      <!-- <template #cell(title)="data"> {{ data }} </template> -->
      <template #cell(status)="data">
        <div>
          <b-avatar size="32" :variant="`light-${data.value ? 'success' : 'danger'}`">
            <feather-icon :icon="data.value ? 'CheckIcon' : 'XIcon'" />
          </b-avatar>
        </div>
      </template>
      <template #cell(actions)="row">
        <!-- <b-button 
            size="sm" 
            @click="showItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="EyeIcon"
              size="15"
            />
          </b-button> -->
        <b-button
          size="sm"
          @click="editItem(row)"
          variant="flat-dark"
          class="btn-icon"
          v-if="resolvePermission('sections.edit')"
        >
          <feather-icon icon="Edit2Icon" size="15" />
        </b-button>
        <b-button
          size="sm"
          @click="deleteItem(row)"
          variant="flat-danger"
          class="btn-icon"
          v-if="resolvePermission('sections.delete')"
        >
          <feather-icon icon="Trash2Icon" size="15" />
        </b-button>
      </template>
    </b-table>
    <p class="text-center py-4" v-if="showedSections.length == 0">
      <span v-if="filterIn">
        {{ $t("noSectionsFound") }}
      </span>
      <span v-else>
        {{ $t("websiteEmbty") }}
      </span>
    </p>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @input="filterSections"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BAvatar,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { mapActions, mapGetters } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import VSelect from "vue-select";
export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BAvatar,
    VSelect
  },
  data() {
    return {
      filterIn: false,
      fields: [
        {
          key: "id",
          label: this.$t("ID"),
        },
        ,
        // {
        //   key: 'description',
        //   label: this.$t('description')
        // },
        {
          key: "title",
          label: this.$t("Section Title"),
          formatter: (value) => {
            if (
              !value ||
              value === "null" ||
              value === "false" ||
              value === "undefined"
            ) {
              return this.$t(this.$i18n.locale) + " " + this.$t("Not activated");
            } else {
              return value;
            }
          },
        },
        {
          key: "custom_fields.section_key",
          label: this.$t("Type"),
          formatter: (value) => this.sectionStyles.find(el=>el.data.key === value)?.title,
        },
        {
          key: "page",
          label: this.$t("Page Name"),
          formatter: (item) =>
            item && item.titles
              ? item.titles[this.$i18n.locale]
              : item && item.title
              ? item.title
              : "",
        },
        {
          key: "status",
          label: this.$t("status"),
        },
        {
          key: "ord",
          label: this.$t("Order"),
        },

        {
          key: "actions",
          label: this.$t("actions"),
        },
      ],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      searchText: "",
      showedSections: [],
      selectedPage: null,
      pagesLoader: false,
    };
  },
  watch: {
    sections(nesections) {
      this.showedSections = nesections;
    },
  },
  props: ["openModal", "closeModal", "confirmDelete"],
  created() {
    this.filterSections();
    this.searchPages()
  },
  methods: {
    ...mapActions([
      "getPages",
      "getAllSections",
      "getSections",
      "deleteSection",
      "getPage",
    ]),
   async searchPages(text){
      this.pagesLoader = true;
      try{
        await this.getPages({
          keywords: text
        })
      }catch(e){}
      this.pagesLoader = false

    },
    filterSections() {
      this.filterIn = true;
      this.getAllSections({
        page_id: this.selectedPage?.id,
        keywords: this.searchText,
        page: this.currentPage,
      }).then((res) => {
        if (res.status == 200) {
          this.showedSections = this.allSections;
          this.rows = res.data.meta.total;
        }
      });
    },
    // filterItems() {
    //   setTimeout(() => {
    //     this.getSections({
    //       page: this.currentPage,
    //       keywords: this.searchText,
    //     }).then((res) => {
    //       this.showedSections = this.sections;
    //       this.rows = res.data.meta.total;
    //     });
    //   }, 100);
    // },
    showItem(row) {
      this.openModal(row, "Show");
    },

    editItem(row) {
      this.openModal(row, "Edit", this.$t("Edit section"),this.filterSections);
    },

    deleteItem(row) {
      this.confirmDelete(this.deleteSection, row);
    },
  },

  computed: {
    ...mapGetters(["sections", "pages", "allSections"]),
    getPagesList() {
      const pagesList = [];
      this.pages.forEach((page) => {
        pagesList.push({ label: page.titles[this.locale], id: page.id });
      });
      return pagesList;
    },
  },
};
</script>
