var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.formLoading)?_c('div',{},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{ref:"newsFormInner"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('name'),"label-for":"name"}},[_c('b-form-input',{attrs:{"type":"text","id":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.formObj.name),callback:function ($$v) {_vm.$set(_vm.formObj, "name", $$v)},expression:"formObj.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4008452845)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('phone'),"label-for":"phone"}},[_c('b-form-input',{attrs:{"type":"text","id":"phone","state":errors.length > 0 ? false : null},model:{value:(_vm.formObj.phone),callback:function ($$v) {_vm.$set(_vm.formObj, "phone", $$v)},expression:"formObj.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1253104429)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('email'),"label-for":"email"}},[_c('b-form-input',{attrs:{"type":"email","id":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.formObj.email),callback:function ($$v) {_vm.$set(_vm.formObj, "email", $$v)},expression:"formObj.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1910469180)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('address'),"label-for":"address"}},[_c('b-form-input',{attrs:{"type":"text","id":"address","state":errors.length > 0 ? false : null},model:{value:(_vm.formObj.address),callback:function ($$v) {_vm.$set(_vm.formObj, "address", $$v)},expression:"formObj.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1381632685)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"booking_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Booking Date: ') + _vm.formObj.booking_date.slice(0, 10),"label-for":"booking_date"}},[_c('b-form-input',{attrs:{"type":"date","id":"booking_date","state":errors.length > 0 ? false : null},model:{value:(_vm.formObj.booking_date),callback:function ($$v) {_vm.$set(_vm.formObj, "booking_date", $$v)},expression:"formObj.booking_date"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2983587797)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"service"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('service'),"label-for":"service"}},[_c('v-select',{attrs:{"id":"service","options":_vm.services,"label":"title","reduce":function (item) { return item.id; }},on:{"search":_vm.searchServices},model:{value:(_vm.formObj.service_id),callback:function ($$v) {_vm.$set(_vm.formObj, "service_id", $$v)},expression:"formObj.service_id"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2177239618)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"data_value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Data Value'),"label-for":"data_value"}},[_c('b-form-input',{attrs:{"type":"number","id":"data_value","state":errors.length > 0 ? false : null},model:{value:(_vm.formObj.data_value[0].value),callback:function ($$v) {_vm.$set(_vm.formObj.data_value[0], "value", $$v)},expression:"formObj.data_value[0].value"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3563936063)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"otherData"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Other Data'),"label-for":"otherData"}},[_c('b-form-group',[_c('b-form-textarea',{attrs:{"id":"otherData"},model:{value:(_vm.formObj.other_data),callback:function ($$v) {_vm.$set(_vm.formObj, "other_data", $$v)},expression:"formObj.other_data"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2482664553)})],1)],1),_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',[_c('b-dropdown',{attrs:{"id":"dropdown-left","text":"status","variant":"primary"},model:{value:(_vm.formObj.status),callback:function ($$v) {_vm.$set(_vm.formObj, "status", $$v)},expression:"formObj.status"}},[_c('b-dropdown-item',{attrs:{"href":"#"}},[_vm._v("Pending")]),_c('b-dropdown-item',{attrs:{"href":"#"}},[_vm._v("Accepted")]),_c('b-dropdown-item',{attrs:{"href":"#"}},[_vm._v("Rejected")])],1)],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }