<template>
  <div class="" v-if="!formLoading">
    <AppForms
      v-if="formObj && !formLoading"
      :defaultItem="defaultItem"
      :editObj="formObj"
      :getRestData="getRestData"
      :formFields="restFields"
      ref="restForm"
    />
  </div>
</template>

<script>
import AppForms from "./AppForms.vue";

import { BRow, BCol } from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    AppForms,
    BRow,
    BCol,
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      defaultItem: {
        status: true,
      },
    };
  },

  props: ["editObj", "closeModal"],
  mounted() {
    if (this.editObj != null) {
      this.getSlider(this.editObj.id).then((res) => {
        this.formObj = res.data.results;
        this.formData.id = this.editObj.id;
        setTimeout(() => {
          this.formLoading = false;
        }, 100);
      })       .catch((err) => {
                    this.$store.commit("setModalLoading", false);
                    this.handleEvent({
                      variant: "danger",
                      icon: "XIcon",
                      msg:
                        err && err.response
                          ? err.response.data.results.message
                          : err,
                    });
                  });
    } else {
      this.formObj = {
        status: true,
      };
    }
  },
  methods: {
    ...mapActions(["getPages", "getSliders", "createSlider", "getSlider"]),
    formSubmit() {
      this.$refs.restForm.submit().then((res) => {
        if (res == true) {
          if (res == true) {
            this.$store.commit("setModalLoading", true);
            this.$refs.restForm.submit();
            setTimeout(() => {
              this.createSlider(this.formData).then((res) => {
                this.closeModal();
                this.getSliders({});
                this.$store.commit("setModalLoading", false);
              })       .catch((err) => {
                    this.$store.commit("setModalLoading", false);
                    this.handleEvent({
                      variant: "danger",
                      icon: "XIcon",
                      msg:
                        err && err.response
                          ? err.response.data.results.message
                          : err,
                    });
                  });
            }, 100);
          }
        }
      });
    },
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      };
    },
  },

  computed: {
    ...mapGetters(["product", "modalLoading", "pages"]),
    restFields() {
      return [
        { name: "Name", type: "text", rules: "required", model: "key" },
        // {
        //   name: "width",
        //   type: "number",
        //   rules: "required|between:50,100",
        //   model: "width",
        // },
        // {
        //   name: "height",
        //   type: "number",
        //   placeholder: "Enter a number of pixels: default is 400px",
        //   rules: "",
        //   model: "height",
        //   append: "px"
        // },
        {
          name: "Status",
          type: "searchSelect",
          rules: "required",
          model: "status",
          searchItems: (t)=>t,
          reducer: (item) => item.value,
          options: [
            {
              label: "Active",
              value: true,
            },
       
            {
              label: "Disabled",
              value: false,
            },
          ],
          cols: 6,
        },

      ];
    },
  },
};
</script>