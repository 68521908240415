import { password } from '@/@core/utils/validations/validations';
import Axios from '../../libs/strataIns';

export default {
  state: {
    user: null,
    loading: false,
    modalLoading: false,
    statsLoading: false,
    profile: null
  },

  getters: {
    user: (state) => state.user,
    getLoading: (state) => state.loading,
    modalLoading: (state) => state.modalLoading,
    statsLoading: (state) => state.statsLoading,
    profile: state=>state.profile
  },

  mutations: {
    setUser(state, payload) {
      state.user = { ...payload };
      // if(payload.user){
      //   state.profile =payload.user
      // }
    },
    setProfile(state, payload) {
      state.profile = { ...payload };
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setModalLoading(state, payload) {
      state.modalLoading = payload;
    },
    setStatsLoading(state, payload) {
      state.statsLoading = payload;
    },

  },

  actions: {
    userLogin({ commit, }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/user/auth`, {}, {
          params: {
            email: payload.email,
            password: payload.password,
          }
        }).then(res => {
          if (res.data.results != null) {
            commit('setProfile', res.data.results.user);
            commit('setUser', res.data.results);
            resolve(res);
          } else {
            reject(res);
          }
        }).catch(err => {
          reject(err);
        })
      })
    },
    userLogout({ commit, }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/user/logout`, {}, {})
          .then(res => {
            if (res.data.results != null) {
              resolve(res);
            } else {
              reject(res);
            }
          }).catch(err => {
            reject(err);
          })
      })
    },
    getUserProfile({ commit, }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/user/profile`).then(res => {
          if (res.data.results != null) {
            commit('setProfile', res.data.results);
            resolve(res);
          } else {
            reject(res);
          }
        }).catch(err => {
          reject(err);
        })
      })
    },
    UserProfileUpdate({ commit, }, payload) {
      const data = {
        id: payload.id,
        name: payload.name,
        email: payload.email,
        phone: payload.phone,
        current_password: payload.current_password,
        password: payload.password,
        password_confirmation: payload.password_confirmation,
        avatar_base64: payload.avatar_base64,
      }
      data.avatar_base64 && data.avatar_base64.file ? true : delete data.avatar_base64;
      data.id ? true : delete data.id;
      return new Promise((resolve, reject) => {
        Axios.post(`/user/profile/update`, data).then(res => {
          if (res.data.results != null) {
            commit('setProfile', res.data.results);
            resolve(res)
          } else {
            reject(res);
          }
        }).catch(err => {
          reject(err);
        })
      })
    },

  }
}