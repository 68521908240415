import { PlayIcon } from 'vue-feather-icons';
import Axios from '../../libs/strataIns';
import i18n from '../../libs/i18n';

export default {
  state: {
    sliders: [],
    banners: [],
    banner: {},
    slider: {},
  },

  getters: {
    sliders: (state) => state.sliders,
    slider: (state) => state.slider,
    banners: (state) => state.banners,
    banner: (state) => state.banner,
  },

  mutations: {
    setSliders(state, payload) {
      state.sliders = [...payload]
    },
    setBanners(state, payload) {
      state.banners = [...payload]
    },
    setSlider(state, payload) {
      state.slider = { ...payload }
    },
    setBanner(state, payload) {
      state.banner = { ...payload }
    },
    removeSlider(state, payload) {
      let index = state.sliders.findIndex(el => el.id == payload);
      state.sliders.splice(index, 1);
    },
    removeBanner(state, payload) {
      let index = state.banners.findIndex(el => el.id == payload);
      state.banners.splice(index, 1);
    },
  },

  actions: {
    getSliders({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/sliders`, window.cleanObject({
          status: payload.status,
          key: payload.key,
        }))
          .then(res => {
            if (res.data.results != null) {
              commit('setSliders', res.data.results);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    getSlider({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/sliders/show/${payload}`, {
        })
          .then(res => {
            if (res.data.results != null) {
              commit('setSlider', res.data.results);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    getBanners({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/banners`, {
          ...payload
        }, {
          headers: {
            'Content-Language': i18n.locale
          }
        })
          .then(res => {
            if (res.data.results != null) {
              commit('setBanners', res.data.results);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    getBanner({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/website/banners/show/${payload}`, {
          status: payload.status,
          page: payload.page,
        })
          .then(res => {
            if (res.data.results != null) {
              commit('setBanner', res.data.results);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    createSlider({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/sliders/save`, {
          id: payload.id,
          key: payload.key,
          width: payload.width,
          height: payload.height,
          status: payload.status
        })
          .then(res => {
            if (res.data.results != null) {
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    createBanner({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/website/banners/save`, {
          id: payload.id,
          titles: payload.titles,
          descriptions: payload.descriptions,
          slider_id: payload.slider_id,
          target: {
            type: payload.action_method,
            target: `${payload.action_target && typeof payload.action_target === "object"?payload.action_target.id:payload.action_target}`,
            button: payload.action_text,
          },
          image_filter: null,
          image_base64: payload.image_base64,
          status: payload.status,
          ord:payload.ord
        })
          .then(res => {
            if (res.data.results != null) {
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    deleteSlider({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/sliders/delete/${payload}`, {})
          .then(res => {
            if (res.data.results != null) {
              commit('removeSlider', payload);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    deleteBanner({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/website/banners/delete/${payload}`, {})
          .then(res => {
            if (res.data.results != null) {
              commit('removeBanner', payload);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },

  }
}