<template>
  <b-form @submit.prevent="submit">
    <b-form-group
      :label="$t('Parent')"
      label-for="parentCat"
      v-if="$route.name == 'Categories'"
    >
      <!-- <b-form-select
        id="parentCat"
        v-model='restData.parent_id'
        :options='filteredCategories'
        value-field="id"
        text-field="title"
      >
      </b-form-select> -->
      <v-select
        id="categories"
        v-model="restData.parent_id"
        :options="filteredCategories"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :reduce="(cat) => cat.id"
        @search="searchCategories"
      >
      </v-select>
    </b-form-group>
    <b-form-group
      :label="$t('Target')"
      label-for="target"
      v-if="$route.name == 'Banners'"
      class="my-1"
    >
      <!-- <b-form-checkbox
        v-model='restData.target.type'
        name="check-button"
        value='url'
        :unchecked-value='null'
        switch
        inline
        class="my-1"
      >
      {{"URL"}}
      </b-form-checkbox> -->
      <b-form-select
        v-model="restData.target.type"
        :options="targetOptions"
        class="my-1"
      ></b-form-select>
      <v-select
        v-if="restData.target.type == 'category'"
        id="categories"
        v-model="restData.target.target"
        :options="categories"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :reduce="(cat) => cat.id"
        @search="searchCategories"
      />
      <v-select
        v-if="restData.target.type == 'product'"
        v-model="restData.target.target"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="products"
        :reduce="(pro) => pro.id"
        @search="searchProducts"
      />
      <b-form-input
        v-if="restData.target.type == 'url'"
        id="target"
        v-model="restData.target.target"
        :disabled="restData.target.type == null"
      >
      </b-form-input>
    </b-form-group>

    <b-row>
      <b-col cols="6">
        <b-form-group :label="$t('Order')" label-for="order">
          <b-form-input type="number" id="order" v-model="restData.ord">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group :label="$t('status')" label-for="status">
          <b-form-checkbox v-model="restData.status" id="status" switch inline>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BRow,
  BCol,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import categories from "@/store/categories/categories";

export default {
  components: {
    vSelect,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
  },
  data() {
    return {
      restData: {
        ord: 0,
        parent_id: null,
        status: true,
        target: {
          type: null,
          target: null,
        },
      },
      options: [],
      targetOptions: ["category", "product", "url"],
    };
  },
  props: ["getRestData", "editObj"],
  mounted() {
    if (this.editObj != null) {
      this.restData.ord = this.editObj.ord;
      if (this.$route.name == "Categories") {
        this.restData.status = this.editObj.status;
        this.restData.parent_id = this.editObj.parent_id;
      } else if (this.$route.name == "Banners") {
        this.editObj.status == "1"
          ? (this.restData.status = true)
          : (this.restData.status = false);
        this.restData.target = this.editObj.target;
      }
    }
  },
  methods: {
    ...mapActions(["getCategories", "getProducts"]),
    submit() {
      this.getRestData({ ...this.restData });
      return true;
    },
    searchCategories(searchText) {
      this.getCategories({
        keywords: searchText,
      });
    },
    searchProducts(value) {
      this.getProducts({
        keywords: value,
      });
    },
  },
  computed: {
    ...mapGetters(["categories", "products"]),
    filteredCategories() {
      return this.categories.filter((el) =>
        el.parent_id == null && this.editObj != null
          ? el.id != this.editObj.id
          : true
      );
    },
  },
};
</script>