import { password } from '@/@core/utils/validations/validations';
import Axios from '../../libs/strataIns';

export default {
  state: {
    roles: [],
    permissions: []
  },

  getters: {
    roles: (state) => state.roles,
    permissions: (state) => state.permissions,
  },

  mutations: {
    setPermissions(state, payload) {
      state.permissions = { ...payload };
    },
    setRoles(state, payload) {
      state.roles = payload;
    },
    removeRole(state, payload) {
      let index = state.roles.findIndex(el => el.id == payload);
      state.roles.splice(index, 1);
    },
  },

  actions: {
    getRoles({ commit, }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/accounts/roles`, {
          keywords: payload.keywords,
          with_permissions: payload.with_permissions,
          with_users: payload.with_users,
          all_locale: true
        }).then(res => {
          if (res.data.results != null) {
            commit('setRoles', res.data.results);
            if (res.data.permissions) {
              commit('setPermissions', res.data.permissions);
            }
            resolve(res);
          } else {
            reject(res);
          }
        }).catch(err => {
          reject(err);
        })
      })
    },
    getPermissions({ commit, }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/accounts/permissions`, {}, {})
          .then(res => {
            if (res.data.results != null) {
              commit('setPermissions', res.data.results);
              resolve(res);
            } else {
              reject(res);
            }
          }).catch(err => {
            reject(err);
          })
      })
    },
    createRole(_con, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/accounts/roles/save`, {
          id: payload.id,
          titles: payload.titles,
          status: payload.status,
          permissions: payload.permissions,
        }).then(res => {
          if (res.data.results != null) {
            resolve(res)
          } else {
            reject(res);
          }
        }).catch(err => {
          reject(err);
        })
      })
    },
    deleteRole({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/accounts/roles/delete/${payload}`, {})
          .then(res => {
            if (res.data.results != null) {
              commit('removeRole', payload);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },

  }
}