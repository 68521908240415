<template>
  <b-card class="py-1 pb-3">
    <AppForms
      v-if="!settingsLoader"
      :editObj="formObj"
      :getRestData="getRestData"
      :formFields="settingsFields"
      ref="restForm"
    />
    <AppForms
      v-if="!settingsLoader"
      :editObj="socialFormData"
      :getRestData="getSocialData"
      :formFields="socialLinks"
      ref="socialForm"
    >
      <b-col>
        <b-button
          style="margin-top: 25px; margin-bottom: 5px"
          @click="addNewItemToSocials"
          variant="primary"
          >Add new social link</b-button
        >
      </b-col>
    </AppForms>
    <b-card-footer class="p-0 pt-1" v-if="!settingsLoader">
      <b-button
        variant="primary"
        class="btn"
        @click="updateTheSettings"
        v-if="resolvePermission('settings.editSettings')"
      >
        {{ $t("Update settings") }}
      </b-button>
    </b-card-footer>
    <div v-else class="text-center text-primary my-2">
      <b-spinner small class="align-middle mx-1"></b-spinner>
      <strong>{{
        $t(updateSettingsLoader ? "Updating..." : "Loading...", {
          items: $t($route.meta.pageTitle),
        })
      }}</strong>
    </div>
    <BModal
      @ok="startAdd"
      ref="addNewItemModal"
      id="addNewItemModal"
      centered
      ok-title="Add link"
      title="Create new social link"
    >
      <BFormGroup label="Link name">
        <BFormInput
          v-model="newItem.name"
          placeholder="Enter link name"
        ></BFormInput>
      </BFormGroup>
      <BFormGroup>
        <template #label>
          Icon (<a href="https://fontawesome.com/v4/icons/" target="_blank"
            >view icons names</a
          >)
        </template>
        <BFormInput
          v-model="newItem.icon"
          placeholder="ex: fa fa-facebook"
        ></BFormInput>
      </BFormGroup>
    </BModal>
  </b-card>
</template>

<script>
import {
  BButton,
  BCardFooter,
  BCard,
  BSpinner,
  BModal,
  BFormInput,
  BFormGroup,
  BCol,
} from "bootstrap-vue";
import { required } from "@validations";
import { mapActions, mapGetters } from "vuex";
import AppForms from "@/@core/components/forms/AppForms.vue";

export default {
  components: {
    BButton,
    BCardFooter,
    BCard,
    AppForms,
    BSpinner,
    BModal,
    BFormInput,
    BFormGroup,
    BCol,
  },
  data() {
    return {
      required,
      formData: {},
      keysInData: [],
      origional: {},
      settingsLoader: false,
      socialLinks: [],
      socialFormData: {},
      formObj: null,
      newItem: {
        icon: "",
        name: "",
      },
      defaultTimeObj: { lang: this.$i18n.locale, ar: "", en: "" },
      delivery_times: [
        { ar: "صباحا", en: "Morning" },
        { ar: "ظهرا", en: "After Noon" },
        { ar: "مساءا", en: "Evening" },
      ],
      updateSettingsLoader: false,
    };
  },
  props: ["confirmDelete"],
  mounted() {
    this.refreshSettings();
  },
  methods: {
    ...mapActions(["getSettings", "updateSettings"]),
    deleteSocialLink(item) {
      this.confirmDelete(null, null, true).then((res) => {
        if (res.value) {
          const index = this.socialLinks.findIndex(
            (link) => link.model == item
          );
          this.socialLinks.splice(index, 1);
          const sIndex = this.formObj.social_media_links.find(
            (el) => `${el.name.toLowerCase().replaceAll(" ", "_")}` == item
          );
          this.formObj.social_media_links.splice(sIndex, 1);
          delete this.socialFormData[item];
        }
      });
    },
    startCollectDataForForm(data) {
      this.formObj = {
        ...data,
        ...data.colorTheme,
        email_auth: data.app_auth.email,
        keyWords: data.keyWords ? data.keyWords.split(",") : [],
        phone_auth: data.app_auth.phone,
        must_verify_code: data.app_auth.must_verify_code,
      };
      this.socialLinks = [];
      this.socialLinks.push({
        name: "Social links",
        type: "groupTitle",
        cols: 12,
      });
      this.socialLinks.push({
        name: "",
        type: "divider",
        cols: 12,
      });
      this.socialLinks.push({
          name: "Twitter Box Username",
          model: "maps_api_key",
          type: "text",
          cols: 4,
          rules: "",
        });
      this.socialFormData = {
        maps_api_key: data.maps_api_key,
      };
      data.social_media_links.forEach((link) => {
        if (link.name) {
          this.socialFormData[link.name.toLowerCase().replaceAll(" ", "_")] =
            link.link;
          this.socialLinks.push({
            name: link.name,
            type: "text",
            model: link.name.toLowerCase().replaceAll(" ", "_"),
            cols: 4,
            rules: "",
            append: "✖",
            appendClick: this.deleteSocialLink,
          });
        }
      });
      this.$forceUpdate();
      setTimeout(() => {
        this.settingsLoader = false;
        this.updateSettingsLoader = false;
      }, 100);
    },
    refreshSettings(forceLoad) {
      this.settingsLoader = true;
      if (this.settings && !forceLoad) {
        this.startCollectDataForForm(this.settings);
      } else {
        this.getSettings()
          .then((res) => {
            this.formObj = null;
            setTimeout(() => {
              this.startCollectDataForForm(res.data.results);
            }, 100);
          })
          .catch((err) => {
            this.settingsLoader = false;
            this.handleEvent({
              icon: "XIcon",
              type: "danger",
              msg: this.$t("error", { error: err }),
            });
          });
      }
    },
    startAdd() {
      if (this.newItem.name && this.newItem.icon) {
        this.socialLinks.push({
          name: this.newItem.name,
          model: this.newItem.name.toLowerCase().replaceAll(" ", "_"),
          type: "text",
          rules: "",
          cols: 4,
        });
        this.formObj.social_media_links.push({
          ...this.newItem,
          link: "",
        });
        this.newItem = {
          name: "",
          icon: "",
        };
        this.$refs["addNewItemModal"].hide();
        this.$forceUpdate();
      } else {
        this.handleEvent({
          type: "danger",
          icon: "XIcon",
          msg: this.$t("Please add name and icon fisrt"),
        });
      }
    },
    addNewItemToSocials() {
      this.$refs["addNewItemModal"].show();
    },
    updateTheSettings() {
      this.$refs.socialForm.submit();
      this.$refs.restForm.submit().then((res) => {
        if (res == true) {
          this.settingsLoader = true;
          this.updateSettingsLoader = true;
          setTimeout(() => {
            this.updateSettings(this.formData)
              .then((res) => {
                this.handleEvent({
                  type: "success",
                  icon: "CheckIcon",
                  msg: this.$t("Settings Updated Successfully"),
                });
                this.settingsLoader = false;
                this.updateSettingsLoader = false;
                this.refreshSettings(true);
              })
              .catch((er) => {
                this.settingsLoader = false;
                this.updateSettingsLoader = false;
                this.handleEvent({
                  type: "danger",
                  icon: "XIcon",
                  msg: this.$t("error", { error: er }),
                });
              });
          }, 200);
        }
      });
    },
    getSocialData(data) {
      const links = [];
      Object.keys(data).forEach((key) => {
        if(key === "maps_api_key"){
          this.formData.maps_api_key = data[key];
          this.formObj.maps_api_key = data[key];
          return;
        }
        links.push({
          name: key,
          link: data[key],
          icon: this.formObj.social_media_links.find((el) => el.name == key)
            ?.icon,
        });
      });
      this.formData.social_media_links = [...links];
      this.formObj.social_media_links = [...links];
    },
    getRestData(data) {
      this.formData = {
        ...data,
        dark_logo: data.dark_base64,
        logo: data.logo_url_base64,
        favicon: data.favicon_url_base64,
        keyWords: data.keyWords ? data.keyWords.join(", ") : "",
        colorTheme: {
          accent: data.accent,
          primary: data.primary,
        },
        app_auth: {
          email: data.email_auth,
          phone: data.phone_auth,
          must_verify_code: data.must_verify_code,
        },
      };
    },
  },

  computed: {
    ...mapGetters(["settings"]),
    settingsFields() {
      return [
        {
          name: "Settings",
          type: "groupTitle",
          cols: 12,
        },
        {
          name: "",
          type: "divider",
          cols: 12,
        },
        {
          name: "app name",
          model: "AppName",
          type: "localizedText",
          cols: 12,
          rules: "required",
        },
        {
          name: "App Description",
          model: "site_meta_description",
          type: "localizedTextarea",
          cols: 12,
          rules: "",
        },
        {
          name: "phone",
          model: "phone",
          type: "number",
          cols: 4,
          place: 0,
          rules: "integer",
          inputId: "mainAppphone",
        },
        {
          name: "Fax number",
          model: "fax",
          type: "number",
          cols: 4,
          place: 0,
          rules: "integer",
        },
        {
          name: "email",
          model: "email",

          type: "email",
          cols: 4,
          rules: "email",
        },

        {
          name: "Address",
          model: "address",
          type: "localizedText",
          cols: 4,
          rules: "required",
        },
        {
          name: "Copy rights",
          model: "copy_rights",
          type: "localizedText",
          cols: 4,
          rules: "required",
        },
        {
          name: "Site meta title",
          model: "site_meta_title",
          type: "localizedText",
          cols: 4,
          rules: "required",
        },

        {
          name: "Facebook pixel",
          model: "facebook_pixel",
          type: "text",
          cols: 4,
          rules: "",
        },
        {
          name: "Googel tag manager",
          model: "google_tag_manager",
          type: "text",
          cols: 4,
          rules: "",
        },
        {
          name: "Keywords",
          type: "searchSelect",
          options: [],
          taggable: true,
          multiple: true,
          placeholder: "For SEO",
          model: "keyWords",
          cols: 4,
          searchItems: (t) => t,

          rules: "",
        },
        // {
        //   name: "Website Settings",
        //   type: "groupTitle",
        //   cols: 12,
        // },
        // {
        //   name: "",
        //   type: "divider",
        //   cols: 12,
        // },
        // {
        //   name: "primary color",
        //   model: "primary",
        //   type: "colorPicker",
        //   cols: 4,
        //   rules: "",
        // },
        // {
        //   name: "secondary color",
        //   model: "accent",
        //   type: "colorPicker",
        //   cols: 4,
        //   rules: "",
        // },
        // {
        //   name: "Footer number of colomns",
        //   model: "footerNumberOfColomns",
        //   type: "number",
        //   cols: 4,
        //   rules: "",
        // },

        // {
        //   name: "Fonts",
        //   type: "searchSelect",
        //   options: [],
        //   searchItems: (t) => t,
        //   taggable: true,
        //   multiple: true,
        //   placeholder: "Enter font name and choose",
        //   model: "fonts",
        //   cols: 4,
        //   rules: "",
        // },
        {
          name: "",
          type: "divider",
          cols: 12,
        },
        {
          name: this.$t("App Logo"),
          model: "logo_file",
          cols: 4,
          type: "fileUpload",
          rules: "",
          accept: "image/*",
          imgPrev: "logo_url",
          exImgPrev: "logo",
          multiple: false,
        },
        {
          name: this.$t("App Favicon"),
          model: "favicon_file",
          cols: 4,
          type: "fileUpload",
          rules: "",
          accept: "image/*",
          exImgPrev: "favicon",
          imgPrev: "favicon_url",
          multiple: false,
        },
        {
          name: this.$t("Login cover"),
          model: "dark_file",
          cols: 4,
          type: "fileUpload",
          rules: "",
          accept: "image/*",
          exImgPrev: "dark_logo",
          imgPrev: "dark",
          multiple: false,
        },

        // {
        //   name: "Authentication settings",
        //   type: "groupTitle",
        //   cols: 12,
        // },
        // {
        //   name: "",
        //   type: "divider",
        //   cols: 12,
        // },
        // {
        //   name: "Login by email",
        //   type: "searchSelect",
        //   options: [
        //     {
        //       title: this.$t("Yes"),
        //       id: true,
        //     },
        //     {
        //       title: this.$t("No"),
        //       id: false,
        //     },
        //   ],
        //   reducer: (item) => item.id,
        //   label: "title",
        //   model: "email_auth",
        //   searchItems: (t) => t,
        //   // initialValue: true,
        //   cols: 4,
        //   rules: "",
        // },
        // {
        //   name: "Login by phone",
        //   type: "searchSelect",
        //   options: [
        //     {
        //       title: this.$t("Yes"),
        //       id: true,
        //     },
        //     {
        //       title: this.$t("No"),
        //       id: false,
        //     },
        //   ],
        //   reducer: (item) => item.id,
        //   label: "title",
        //   model: "phone_auth",
        //   searchItems: (t) => t,
        //   // initialValue: true,
        //   cols: 4,
        //   rules: "",
        // },
        // {
        //   name: "Use OTP",
        //   type: "searchSelect",
        //   options: [
        //     {
        //       title: this.$t("Yes"),
        //       id: true,
        //     },
        //     {
        //       title: this.$t("No"),
        //       id: false,
        //     },
        //   ],
        //   reducer: (item) => item.id,
        //   label: "title",
        //   model: "must_verify_code",
        //   searchItems: (t) => t,
        //   // initialValue: true,
        //   cols: 4,
        //   rules: "",
        // },
        // {
        //   name: "Verification Code Length",
        //   model: "verification_code_length",
        //   type: "number",
        //   cols: 4,
        //   rules: "integer",
        // },
      ];
    },
  },
};
</script>
