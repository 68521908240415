<template>
  <div class="" v-if="!formLoading">
    <validation-observer ref="simpleRules">
      <b-form ref="newsFormInner">
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="name"
            >
              <b-form-group :label="$t('name')" label-for="name">
                <b-form-input
                  type="text"
                  id="name"
                  v-model="formObj.name"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="phone"
            >
              <b-form-group :label="$t('phone')" label-for="phone">
                <b-form-input
                  type="text"
                  id="phone"
                  v-model="formObj.phone"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="email"
            >
              <b-form-group :label="$t('email')" label-for="email">
                <b-form-input
                  type="email"
                  id="email"
                  v-model="formObj.email"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="address"
            >
              <b-form-group :label="$t('address')" label-for="address">
                <b-form-input
                  type="text"
                  id="address"
                  v-model="formObj.address"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="booking_date"
            >
              <b-form-group
                :label="
                  $t('Booking Date: ') + formObj.booking_date.slice(0, 10)
                "
                label-for="booking_date"
              >
                <b-form-input
                  type="date"
                  id="booking_date"
                  v-model="formObj.booking_date"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="service"
            >
              <b-form-group :label="$t('service')" label-for="service">
                <v-select
                  id="service"
                  :options="services"
                  label="title"
                  :reduce="(item) => item.id"
                  v-model="formObj.service_id"
                  @search="searchServices"
                ></v-select>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="ord"
            >
              <b-form-group :label="$t('Order')" label-for="ord">
                <b-form-input
                  type="number"
                  id="ord"
                  v-model="formObj.ord"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>-->
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="data_value"
            >
              <b-form-group :label="$t('Data Value')" label-for="data_value">
                <b-form-input
                  type="number"
                  id="data_value"
                  v-model="formObj.data_value[0].value"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="order"
            >
              <b-form-group :label="$t('Order')" label-for="order">
                <b-form-input type="number" id="order" v-model="formObj.ord">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col> -->

          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="otherData"
            >
              <b-form-group :label="$t('Other Data')" label-for="otherData">
                <!-- <quillEditor
                      v-model="formObj.descriptions"
                      :placeholder="Description"
                      id="desc"
                      style="z-index: 50; position: relative"
                    ></quillEditor> -->
                <b-form-group>
                  <b-form-textarea id="otherData" v-model="formObj.other_data">
                  </b-form-textarea>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <!-- <b-col cols="10">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="img"
            >
              <b-form-group>
                <b-form-file
                  id="img"
                  v-model="imgeviwe"
                  @change="getImage"
                ></b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col> -->
          <b-col cols="2">
            <b-form-group>
              <b-dropdown
                id="dropdown-left"
                text="status"
                variant="primary"
                v-model="formObj.status"
              >
                <b-dropdown-item href="#">Pending</b-dropdown-item>
                <b-dropdown-item href="#">Accepted</b-dropdown-item>
                <b-dropdown-item href="#">Rejected</b-dropdown-item>
              </b-dropdown>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'
import ResponsiveFieldsForm from "./ResponsiveFieldsForm.vue";
import BaseForm from "./BaseForm.vue";
import ProductRestForm from "./ProductRestForm.vue";
import ImgForm from "./ImgForm.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BFormInput,
  BFormFile,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BInputGroupAppend,
  BForm,
  BInputGroup,
  BButton,
  BTabs,
  BTab,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ResponsiveFieldsForm,
    quillEditor,
    BaseForm,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BInputGroupAppend,
    BButton,
    BTabs,
    BTab,
    BFormCheckbox,
    BFormTextarea,
    ProductRestForm,
    ImgForm,
    BRow,
    BCol,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      nameLang: "ar",
      descLang: "ar",
      formObj: {
        name: "",
        phone: "",
        email: "",
        service_id: "",
        address: "",

        other_data: "",
        booking_date: "",
        ord: "",
        status: true,
      },
      editMood: false,
      formLoading: false,
      formData: {},
    };
  },

  props: ["editObj", "closeModal"],
  mounted() {
    this.formLoading = true;
    if (this.editObj != null) {
      this.editMood = true;
      this.loadSrRequest(this.editObj.id).then((res) => {
        this.formObj = res.data.results;
        setTimeout(() => {
          this.formLoading = false;
        }, 2000);
      });
    } else {
      this.editMood = false;
      this.formLoading = false;
    }
  },
  methods: {
    ...mapActions([
      "loadSrRequest",
      "updateSrRequest",
      "loadsrRequests",
      "createSrRequest",
      "loadServices",
    ]),
    // changeNameLang() {
    //   if (this.nameLang == "ar") {
    //     this.nameLang = "en";
    //   } else {
    //     this.nameLang = "ar";
    //   }
    // },
    // getImage() {
    //   setTimeout(() => {
    //     let url = URL.createObjectURL(this.imgeviwe);
    //     this.imgPrev = url;
    //     this.newImageUploaded = true;
    //   }, 500);
    // },
    formSubmit() {
      this.$store.commit("setModalLoading", true);
      this.formData = {
        name: this.formObj.name,
        phone: this.formObj.phone,
        service_id: this.formObj.service_id,
        // data_value: [
        //   {
        //     id: this.formObj.data_value[0].id,
        //     value: this.formObj.data_value[0].value,
        //   },
        // ],
        other_data: this.formObj.other_data,
        booking_date: this.formObj.booking_date,
        ord: this.formObj.ord,
        status: this.formObj.status,
      };
      if (this.editMood) {
        this.updateSrRequest(this.formData)
          .then((res) => {
            if (res.status == 200) {
              this.closeModal();
              this.loadsrRequests();
              this.$store.commit("setModalLoading", false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.createSrRequest(this.formData).then((res) => {
          if (res.status == 200) {
            this.closeModal();
            this.loadsrRequests();
            this.$store.commit("setModalLoading", false);
          }
        });
      }
    },
    searchPages(searchText) {
      this.getPages({
        path: searchText,
        status: 1,
      });
    },
    searchLabels(searchText) {
      this.getLabels({
        path: searchText,
        status: 1,
      });
    },
    searchServices(searchText) {
      this.loadServices({
        path: searchText,
        status: 1,
      });
    },
  },

  computed: {
    ...mapGetters(["modalLoading", "services"]),
  },
};
</script>