<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t($route.name) }}</b-card-title>
      <b-button
        class=""
       variant="primary"
        @click="openModal(null, 'Create','Edit Service', filterItems)"
        v-if="resolvePermission('services.create')"
      >
        <feather-icon icon="PlusIcon" size="15"> </feather-icon>
        {{ $t("add new") }}
      </b-button>
    </b-card-header>
    <b-row class="mx-1">
      <b-col class="col-9 col-md-11">
        <b-form-group :label="$t('Search')" label-for="basicInput">
          <b-form-input id="basicInput" v-model="searchText" />
        </b-form-group>
      </b-col>
      <b-col class="col-3 col-md-1 align-self-center">
        <b-button
          variant="primary"
          class="btn my-auto"
          :disabled="getLoading"
          @click="filterItems"
        >
          <feather-icon icon="SearchIcon" size="16"></feather-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-table responsive :items="services" :fields="fields" class="mb-0">
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
      <template #cell(title)="data">
        <p class="text-nowrap">
          {{ data.value }}
        </p>
      </template>
      <!-- <template #cell(titles)="data"> -->
      <!-- </template> -->

      <template #cell(image)="data">
        <img
        v-if="data.item.image_url"
          :src="data.item.image_url"
          alt="Service Image"
          width="40"
          height="38"
        />
      </template>
      <template #cell(status)="data">
        <div>
          <b-avatar
            size="32"
            :variant="`light-${data.value ? 'success' : 'danger'}`"
          >
            <feather-icon :icon="data.value ? 'CheckIcon' : 'XIcon'" />
          </b-avatar>
        </div>
      </template>
      <template #cell(actions)="row">

        <b-button
          size="sm"
          @click="editItem(row)"
          variant="flat-dark"
          class="btn-icon"
          v-if="resolvePermission('services.edit')"
        >
          <feather-icon icon="Edit2Icon" size="15" />
        </b-button>
        <b-button
          size="sm"
          @click="deleteItem(row)"
          variant="flat-danger"
          class="btn-icon"
          v-if="resolvePermission('services.delete')"
        >
          <feather-icon icon="Trash2Icon" size="15" />
        </b-button>
      </template>
    </b-table>
    <p class="text-center py-4" v-if="services.length == 0">
      {{ $t("no") + $t($route.name) + $t("noDataHint") }}
    </p>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @input="filterItems"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BAvatar,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { mapActions, mapGetters } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BAvatar,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("ID"),
        },
        {
          key: "image",
          label: this.$t("Image"),
        },
        {
          key: "title",
          label: this.$t("Title"),
          formatter: (value)=> {
            if(!value || value === 'null'|| value === 'false' || value === 'undefined'){
              return this.$t(this.$i18n.locale) +" "+ this.$t("Not activated");
            }else{
              return value
            }
          }
        },
        {
          key: "status",
          label: this.$t("status"),
        },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      searchText: "",
    };
  },
  props: ["openModal", "closeModal", "confirmDelete"],
  created() {
    this.filterItems()
  },

  methods: {
    ...mapActions(["loadServices", "removeService"]),
    filterItems() {
        this.loadServices({
          page: this.currentPage,
          keywords: this.searchText,
        }).then((res) => {
          this.rows = res.data.results.total;
        });
    },
    editItem(row) {
      this.openModal(row, "Edit","Edit Service", this.filterItems);
    },

    deleteItem(row) {
      this.confirmDelete(this.removeService, row);
    },
  },

  computed: {
    ...mapGetters(["services"]),
    perPageCount() {
      return this.services.length;
    },
  },
};
</script>