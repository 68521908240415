<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t($route.name) }}</b-card-title>
      <b-button class=""variant="primary" @click="openModal(null, 'Create')">
        <feather-icon icon="PlusIcon" size="15"> </feather-icon>
        {{ $t("add new") }}
      </b-button>
    </b-card-header>
    <b-row class="mx-1">
      <b-col class="col-9 col-md-11">
        <b-form-group :label="$t('Search')" label-for="basicInput">
          <b-form-input id="basicInput" v-model="searchText" />
        </b-form-group>
      </b-col>
      <b-col class="col-3 col-md-1 align-self-center">
        <b-button
          variant="primary"
          class="btn my-auto"
          :disabled="getLoading"
          @click="filterItems"
        >
          <feather-icon icon="SearchIcon" size="16"></feather-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-table responsive :items="roles" :fields="fields" class="mb-0">
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        <p class="text-nowrap">
          {{ data.value }}
        </p>
      </template>
      <template #cell(status)="data">
        <div>
          <b-avatar
            size="32"
            :variant="`light-${data.value ? 'success' : 'danger'}`"
          >
            <feather-icon :icon="data.value ? 'CheckIcon' : 'XIcon'" />
          </b-avatar>
        </div>
      </template>
      <template #cell(actions)="row">
        <!-- <b-button 
            size="sm" 
            @click="showItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="EyeIcon"
              size="15"
            />
          </b-button> -->
        <b-button
          size="sm"
          @click="editItem(row)"
          variant="flat-dark"
          class="btn-icon"
          v-if="isEditable(row)"
        >
          <feather-icon icon="Edit2Icon" size="15" />
        </b-button>
        <b-button
          size="sm"
          @click="deleteItem(row)"
          variant="flat-danger"
          class="btn-icon"
          v-if="isDeletable(row)"
        >
          <feather-icon icon="Trash2Icon" size="15" />
        </b-button>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @change="filterItems"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BAvatar,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { mapActions, mapGetters } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BAvatar,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("ID"),
        },
        ,
        {
          key: "title",
          label: this.$t("name"),
          formatter: (value, key, item) => item.titles[this.locale],
        },
        {
          key: "users_count",
          label: this.$t("Count"),
        },
        {
          key: "created_at",
          label: this.$t("Create Date"),
          formatter: (value, key, item) => value.split("T")[0],
        },
        {
          key: "status",
          label: this.$t("status"),
        },
        // {
        //   key: 'created_at',
        //   label: 'date',
        //   formatter: (value, key, item) => this.formateDate(item),
        // },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      searchText: "",
      currentPermissions: [],
    };
  },
  watch: {
    $route: function () {
      this.currentPermissions = [this.$route.meta.userRole];
      this.filterItems();
    },
  },
  props: ["openModal", "closeModal", "confirmDelete"],
  created() {
    this.currentPermissions = [this.$route.meta.userRole];
    this.getRoles({
      with_permissions: true,
      with_user: false,
    })
      .then((res) => {
        // console.log(res)
        this.rows = res.data.meta.total;
      })
      .catch((err) => console.error(err));
  },

  methods: {
    ...mapActions(["getRoles", "deleteRole", "getPermissions"]),
    filterItems() {
      setTimeout(() => {
        this.getRoles({
          page: this.currentPage,
          keywords: this.searchText == "" ? undefined : this.searchText,
          with_permissions: true,
          with_user: false,
        })
          .then((res) => {
            this.rows = res.data.meta.total;
          })
          .catch((err) => {});
      }, 100);
    },
    showItem(row) {
      this.openModal(row, "Show");
      // console.log(row)
    },

    editItem(row) {
      this.openModal(row, "Edit");
    },

    deleteItem(row) {
      this.confirmDelete(this.deleteRole, row);
    },
    isEditable(row) {
      if (row.item.permissions.includes("admin")) {
        return false;
      } else return true;
    },
    isDeletable(row) {
      let roles = ["admin", "users.driver", "users.seller"];
      if (row.item.permissions.some((el) => roles.includes(el))) {
        return false;
      } else return true;
    },
  },

  computed: {
    ...mapGetters(["filteredUsers", "roles", "permissions", "getLoading"]),
  },
};
</script>