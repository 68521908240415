import i18n from '@/libs/i18n';
import Axios from '../../libs/strataIns';

export default {
  state: {
    pages: [],
    page: {},

    // ordersStatus: [],
    // orderStatus: {},
  },

  getters: {
    pages: (state) => state.pages,
    page: (state) => state.page,
    // ordersStatus: (state) => state.ordersStatus,
    // orderStatus: (state) => state.orderStatus,
  },

  mutations: {
    setPages(state, payload) {
      state.pages = [...payload]
    },
    setPage(state, payload) {
      state.page = { ...payload }
    },
    addPage(state, payload) {
      state.pages.push(payload);
    },
    updatePage(state, payload) {
      let index = state.pages.findIndex(el => el.id == payload.id);
      state.pages.splice(index, 1, payload);
    },
    removePage(state, payload) {
      let index = state.pages.findIndex(el => el.id == payload);
      state.pages.splice(index, 1);
    }

  },

  actions: {
    getPages({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/pages`, {
          keywords: payload.keywords,
          page: payload.page,
        })
          .then(res => {
            if (res.data.results.data != null) {
              commit('setPages', res.data.results.data);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    getPage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/pages/show/${payload}`, {
          'Content-Language': i18n.locale
        })
          .then(res => {
            if (res.data.results != null) {
              commit('setPage', res.data.results);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    createPage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/pages/save`, {
          id: payload.id,
          titles: payload.titles,
          path: payload.path,
          category_id: payload.parent_id,
          solution_id: payload.solution_id,
          labels: payload.labels,
          status: payload.status ? payload.status : 0,
          client_name: payload.client_name,
          cost: payload.cost,
          duration: payload.duration,
          ord: payload.ord,
          images: payload.base64,
        })
          .then(res => {
            if (res.data.results != null) {
              if (payload.id) {
                commit('updatePage', res.data.results);
              } else {
                commit('addPage', res.data.results);
              }
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    deletePage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/pages/delete/${payload}`, {})
          .then(res => {
            if (res.data.results != null) {
              commit('removePage', payload);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },

  }
}