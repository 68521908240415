<template>
  <div class="" v-if="!formLoading">
    <AppForms
      v-if="formObj != null && !formLoading"
      :editObj="formObj"
      :getRestData="getRestData"
      :formFields="restFields"
      ref="restForm"
    />
  </div>
  <div
    v-else-if="formLoading"
    class="text-center py-5 d-flex align-items-center justify-content-center"
  >
    <b-spinner small class="mx-1" /> {{ $t("Loading...") }}
  </div>
</template>

<script>
import AppForms from "./AppForms.vue";
import { BSpinner } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    AppForms,
    BSpinner,
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
    };
  },

  props: ["editObj", "closeModal", "load"],
  mounted() {
    if (this.editObj != null) {
      this.formLoading = true;
      this.loadShowedService(this.editObj.id)
        .then((res) => {
          this.formObj = {
            ...res.data.results,
            data_titles: res.data.results.data ? res.data.results.data.titles : {},
            data_descriptions: res.data.results.data
              ? res.data.results.data.descriptions
              : {},
          };
          this.formLoading = false;
          this.formData.id = this.editObj.id;
        })
        .catch((err) => {
          this.formLoading = false;
          this.handleEvent({
            variant: "danger",
            icon: "XIcon",
            msg: err && err.response ? err.response.data.results.message : err,
          });
        });
    } else {
      this.formObj = {
        title: {},
      };
    }
  },
  methods: {
    ...mapActions(["createService", "loadShowedService", "updateService"]),
    formSubmit() {
      this.$refs.restForm.submit().then((baseRes) => {
        if (baseRes == true) {
          this.$store.commit("setModalLoading", true);
          setTimeout(() => {
            this.createService(this.formData)
              .then((res) => {
                  if (this.load) {
                    this.load();
                  }
                  this.closeModal();
                  this.$store.commit("setModalLoading", false);
              })
              .catch((err) => {
                this.$store.commit("setModalLoading", false);
                this.handleEvent({
                  variant: "danger",
                  icon: "XIcon",
                  msg: err && err.response ? err.response.data.results.message : err,
                });
              });
          }, 100);
        }
      });
    },
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
        data: {
          titles: data.data_titles,
          descriptions: data.data_descriptions,
        },
      };
    },
  },

  computed: {
    ...mapGetters(["product", "modalLoading"]),

    restFields() {
      return [
        {
          name: "Service title",
          type: "localizedText",
          model: "titles",
          cols: 12,
          rules: "required",
        },
        {
          name: "Short description",
          type: "localizedTextarea",
          model: "descriptions",
          cols: 12,
          rules: "",
        },

        {
          name: "Details title",
          type: "localizedText",
          model: "data_titles",
          cols: 12,
          rules: "",
        },
        {
          name: "Details",
          type: "localizedHTML",
          model: "data_descriptions",
          cols: 12,
          rules: "",
        },
        {
          name: "ord",
          type: "number",
          rules: "",
          model: "ord",
          placeholder: "Order",
        },
        {
          name: "Status",
          type: "searchSelect",
          options: [
            {
              title: this.$t("Active"),
              id: true,
            },
            {
              title: this.$t("Disabled"),
              id: false,
            },
          ],
          reducer: (item) => item.id,
          label: "title",
          model: "status",
          searchItems: (t) => t,
          initialValue: true,
          cols: 6,
          rules: "required",
        },
        {
          name: "Image",
          model: "image_file",
          cols: 12,
          thePlaceholder: this.$t("Upload/Update Image"),
          type: "fileUpload",
          rules: "",
          accept: "image/*",
          exImgPrev: "image_url",
          imgPrev: "image",
          fileClass: "full",
          mainClass: "flex-column",
          multiple: false,
        },
      ];
    },
  },
};
</script>
