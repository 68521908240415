<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title
        >{{ $t("Purchasing") }} ( {{ recieptsMeta.total }} )
      </b-card-title>
      <!-- <b-button
              class=""
              variant='success'
              @click='openModal(null, "Create")'
            >
            <feather-icon
              icon="PlusIcon"
              size="15"
            >

            </feather-icon>
            {{$t('add new')}}
          </b-button> -->
    </b-card-header>
    <b-row class="mx-2">
      <b-col cols="4">
        <b-form-group :label="$t('from Date')" label-for="fromdate">
          <b-form-datepicker
            v-model="fromDate"
            :date-disabled-fn="dateDisabled"
            :locale="locale == 'en' ? 'en' : 'ar-EG'"
            id="fromdate"
            @input="filterItems"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group :label="$t('To Date')" label-for="todate">
          <b-form-datepicker
            v-model="toDate"
            :date-disabled-fn="dateDisabled"
            :locale="locale == 'en' ? 'en' : 'ar-EG'"
            id="todate"
            @input="filterItems"
          />
        </b-form-group>
      </b-col>
      <!-- <b-col>
           <b-form-group
            :label="$t('Categories')"
            label-for="categories"
           >
            <b-form-datepicker
              v-model="toDate"
              :date-disabled-fn="dateDisabled"
              locale="en"
              id='categories'
            />
           </b-form-group>
          
        </b-col> -->
      <!-- <b-col>
          <b-form-group
            label="Basic Input"
            label-for="selectInput"
          >
            <b-form-select
              id='selectInput'
              v-model="selected"
              :options="options"
            />
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="options"
            />
          </b-form-group>
        </b-col> -->
      <!-- <b-col cols='1' class="align-self-center">
          <b-button 
            variant='primary'
            class="btn my-auto"
            :disabled ='loading'
            @click="filterItems"
          >
            <feather-icon
              icon='SearchIcon'
              size='16'
            ></feather-icon>
          </b-button>
        </b-col> -->
    </b-row>

    <b-table responsive :items="reciepts" :fields="fields" class="mb-0">
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>

      <template #cell(supplier)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.supplier.name)"
              :variant="`light-${
                resolveRecieptStatus(data.item.status).variant
              }`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.supplier.name }}
          </span>
          <small class="text-muted">{{ data.item.supplier.phone }}</small>
        </b-media>
      </template>

      <template #cell(status)="data">
        <b-row>
          <b-col>
            <b-avatar
              :id="`invoice-row-${data.item.id}`"
              size="32"
              :variant="`light-${
                resolveRecieptStatus(data.item.status).variant
              }`"
            >
              <feather-icon
                :icon="resolveRecieptStatus(data.item.status).icon"
              />
            </b-avatar>
            <b-tooltip :target="`invoice-row-${data.item.id}`" placement="top">
              <p class="mb-0">
                Status: {{ resolveRecieptStatus(data.item.status).title }}
              </p>
              <!-- <p class="mb-0">
                  Payment Status: {{ data.item.payments_status && data.item.payments_status.paid == true ? 'Paid' : 'Not Paid'}}
                </p>
                <p class="mb-0">
                  Total: {{ data.item.total_net }}
                </p> -->
            </b-tooltip>
          </b-col>
          <!-- <b-col >
            <b-form-select
              :options='ordersStatus'
              text-field="title"
              value-field="id"
              v-model='data.item.status_id'
              @change='saveOrder(data.item)'
            ></b-form-select>
          </b-col> -->
        </b-row>
      </template>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          @click="showItem(row)"
          variant="flat-dark"
          class="btn-icon"
        >
          <feather-icon icon="EyeIcon" size="15" />
        </b-button>
        <!-- <b-button 
            size="sm" 
            @click="editItem(row)"
            variant="flat-dark"  
            class="btn-icon"
            :disabled='row.item.status == "2" || row.item.status == 2'
          >
            <feather-icon
              icon="Edit2Icon"
              size="15"
            />
          </b-button>
          <b-button 
            size="sm" 
            @click="deleteItem(row)"
            variant="flat-danger"  
            class="btn-icon"
            :disabled='row.item.status == "2" || row.item.status == 2'
          >
            <feather-icon
              icon="Trash2Icon"
              size="15"
            />
          </b-button> -->
        <b-button
          size="sm"
          @click="downloadInvoice(row)"
          variant="flat-secondary"
          class="btn-icon"
        >
          <feather-icon icon="DownloadIcon" size="15" />
        </b-button>
      </template>
    </b-table>

    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @change="filterItems"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BTooltip,
  BAvatar,
  BBadge,
  BMedia,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { mapActions, mapGetters } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    BFormGroup,
    BFormInput,
    BFormSelect,
    vSelect,
    BPagination,
    BTooltip,
    BAvatar,
    BBadge,
    BMedia,
    BFormDatepicker,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("ID"),
          sortable: true,
        },
        {
          key: "supplier",
          label: this.$t("Supplier"),
          sortKey: "supplier.name",
          sortable: true,
        },
        {
          key: "supplier.notes",
          label: this.$t("Notes"),
        },
        {
          key: "status",
          label: this.$t("status"),
          // formatter()
          sortable: true,
        },

        {
          key: "value",
          label: this.$t("Value"),
          // formatter()
          sortable: true,
        },

        // {
        //   key: 'created_at',
        //   label: 'date',
        //   formatter: (value, key, item) => this.formateDate(item),
        // },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ],
      fromDate: "",
      toDate: "",
      searchText: "",
      options: [],
      selected: "",
      currentPage: 1,
      perPage: 10,
      rows: 0,
    };
  },
  props: ["openModal", "closeModal", "confirmDelete"],
  created() {
    this.getReciepts({})
      .then((res) => {
        this.rows = res.data.meta.total;
      })
      .catch((err) => console.error(err));
  },

  methods: {
    ...mapActions([
      "getReciepts",
      "deleteCategory",
      "createCategory",
      "getCategory",
      "deleteReciept",
    ]),
    filterItems() {
      setTimeout(() => {
        this.getReciepts({
          page: this.currentPage,
          from_date: this.fromDate,
          to_date: this.toDate,
          keywords: this.searchText,
        })
          .then((res) => {})
          .catch((err) => {});
      }, 100);
    },

    showItem(row) {
      this.openModal(row, "ShowHomeReciept");
    },

    editItem(row) {
      this.openModal(row, "Edit");
    },

    deleteItem(row) {
      this.confirmDelete(this.deleteReciept, row);
    },
    downloadInvoice(row) {
      this.openModal(row, "ShowHomeReciept");
      let interval = setInterval(() => {
        if (this.loading || this.modalLoading) {
        } else {
          window.print();
          clearInterval(interval);
        }
      }, 300);
    },
    dateDisabled() {},
  },

  computed: {
    ...mapGetters(["reciepts", "loading", "modalLoading", "recieptsMeta"]),
  },

  setup(props) {
    return {
      avatarText,
    };
  },
};
</script>