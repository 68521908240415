import Axios from '../../libs/strataIns';
import auth from '../auth/auth';
import i18n from '../../libs/i18n';
import store from '../auth/auth';
function parseString(string) {
  if (typeof string == "string" && string.length <= 9) {
    if (string == 'undefined') {
      return false
    } else {
      return JSON.parse(string);
    }
  } else {
    return string;
  }
}
export default {
  state: {
    sections: [],
    section: {},
    allSections: []
  },

  getters: {
    sections: (state) => state.sections,
    allSections: (state) => state.allSections,
    section: (state) => state.section,
  },

  mutations: {

    setSections(state, payload) {
      state.sections = [...payload]
    },

    setAllSections(state, payload) {
      state.allSections = [...payload]
    },
    // setProductsMeta(state, payload) {
    //   state.sections = {...payload}
    // },
    setSection(state, payload) {
      state.section = { ...payload }
    },
    removeSection(state, payload) {
      let index = state.sections.findIndex(el => el.id == payload);
      if(index !== -1) {
        state.sections.splice(index, 1);
      }
    }

  },

  actions: {
    getSections({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/sections`, {
          id: payload.id,
          keywords: payload.keywords,
          category_id: payload.category_id,
          status: payload.status,
          page: payload.page,
          load_categories: payload.load_categories,
          export: payload.export,
          sort_by: payload.sort_by,
          sort_as: payload.sort_as,
          limit: payload.limit,
          page_id: payload.page_id
        })
          .then(res => {
            if (res.data.results != null) {
              commit('setSections', res.data.results);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    getAllSections({ commit }, payload,) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/sections`, window.cleanObject(payload)).then(res => {
          if (res.status = 200) {
            commit('setAllSections', res.data.results);
            resolve(res);
          }
        }).catch(err => {
          reject(err)
        });
      })
    },
    getSection({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/sections/show/${payload}`, {
        })
          .then(res => {
            if (res.data.results != null) {
              commit('setSection', res.data.results);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    createSection(_cont, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/sections/save`, window.cleanObject({
          id: payload.id,
          titles: payload.titles,
          descriptions: payload.descriptions,
          page_id: payload.page_id,
          status: payload.status,
          ord: payload.ord,
          media: payload.media,
          custom_fields: window.cleanObject(payload.custom_fields)
        }))
          .then(res => {
            if (res.data.results != null) {
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    deleteSection({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`admin/sections/delete/${payload}`, {})
          .then(res => {
            if (res.data.results != null) {
              commit('removeSection', payload);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
  },
  modules: {
    // auth     
  }
}