import Vue from 'vue';
import Vuex from 'vuex';
import VueCryptojs from 'vue-cryptojs';


// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

import users from './users/users';
import orders from './orders/orders';
import auth from './auth/auth';
// import products from './products/products';

// import projects from './projects/projects';
import categories from './categories/categories'
import testmonials from './testmonials/testmonials';
import contactus from './contactus/contactus';
import solutions from './solutions/solutions';
import jobs from './jobs/jobs';
import sections from './sections/sections';
import pages from './pages/pages';
import labels from './labels/labels';
import settings from './settings/settings';
import sliders from './sliders/sliders';
import subscribe from './subscribe/subscribe';
import news from "./News/News";
import services from "./services/services";
import roles from "./roles/roles";
import servicesRequests from "./services requests/services-requests.js";
Vue.use(Vuex);
Vue.use(VueCryptojs);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    servicesRequests,
    users,
    orders,
    auth,
    // products,
    services,
    categories,
    // offers,
    // projects,
    contactus,
    testmonials,
    jobs,
    sections,
    pages,
    solutions,
    labels,
    roles,
    settings,
    sliders,
    subscribe,
    news
  },
  strict: process.env.DEV,
});
