<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t($route.name) }}</b-card-title>
      <div class="">
        <b-button
          class="mx-2"
          variant="secondary"
          @click="exportData('accounts/export', params)"
        >
          <feather-icon icon="DownloadIcon" size="15"> </feather-icon>
          {{ $t("Export") }}
        </b-button>
        <b-button class=""variant="primary" @click="openModal(null, 'Create')">
          <feather-icon icon="PlusIcon" size="15"> </feather-icon>
          {{ $t("add new") }}
        </b-button>
      </div>
    </b-card-header>
    <b-form @submit.prevent="filterItems">
      <b-row class="mx-2">
        <b-col>
          <b-form-group :label="$t('Search')" label-for="basicInput">
            <b-form-input id="basicInput" v-model="searchText" />
          </b-form-group>
        </b-col>
        <!-- <b-col>
            <b-form-group
              label="Basic Input"
              label-for="selectInput"
            >
              <b-form-select
                id='selectInput'
                v-model="selected"
                :options="options"
              />
              <v-select
                v-model="selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="options"
              />
            </b-form-group>
          </b-col> -->
        <b-col>
          <b-form-group :label="$t('limit')" label-for="perPage">
            <b-form-select
              id="perPage"
              v-model="perPage"
              :options="perPageOptions"
              @input="changeFocus('submitBtn')"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="align-self-center">
          <b-button
            id="submitBtn"
            variant="primary"
            class="btn my-auto w-100"
            :disabled="getLoading"
            type="submit"
          >
            <feather-icon icon="SearchIcon" size="16"></feather-icon>
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-table responsive :items="users" :fields="fields" class="mb-0">
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>

      <template #cell(image)="data">
        <img
          :src="data.item.image_url ? data.item.image_url : placeHolder"
          width="40"
          height="38"
        />
      </template>

      <template #cell(client)="data">
        <!-- <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.name)"
              :variant="`light-primary`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.name }}
          </span>
          <small class="text-muted">{{ data.item.phone }}</small>
        </b-media> -->
        {{ data.item.name }}
      </template>
      <template #cell(actions)="row">
        <!-- <b-button 
            size="sm" 
            @click="showItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="EyeIcon"
              size="15"
            />
          </b-button> -->
        <!-- <b-button 
            size="sm" 
            @click="editItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="Edit2Icon"
              size="15"
            />
          </b-button> -->
        <!-- <b-button 
            size="sm" 
            @click="deleteItem(row)"
            variant="flat-danger"  
            class="btn-icon"
          >
            <feather-icon
              icon="Trash2Icon"
              size="15"
            />
          </b-button> -->
        <b-button
          size="sm"
          @click="showOrders(row)"
          variant="flat-primary"
          class="btn-icon"
        >
          <feather-icon icon="BoxIcon" size="15" />
        </b-button>
      </template>
    </b-table>

    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @change="filterItems"
        :limit="15"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BMedia,
  BAvatar,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { mapActions, mapGetters } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    BFormGroup,
    BFormInput,
    BFormSelect,
    vSelect,
    BMedia,
    BAvatar,
    BForm,
    BPagination,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("ID"),
          // sortable: true
        },
        {
          key: "client",
          label: this.$t("name"),
        },
        {
          key: "phone",
          label: this.$t("phone"),
          // sortable: true
        },
        {
          key: "created_at",
          label: "date",
          formatter: (value, key, item) => this.formateDate(item),
          // sortable: true,
        },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ],
      searchText: "",
      options: [],
      selected: "",
      currentPage: 1,
      perPage: 10,
      rows: 0,
    };
  },
  props: ["openModal", "closeModal", "confirmDelete"],
  created() {
    this.getUsers({
      role: "users",
    })
      .then((res) => {
        this.rows = res.data.meta.total;
      })
      .catch((err) => console.error(err));
  },

  methods: {
    ...mapActions(["getUsers", "getBanner"]),
    filterItems() {
      setTimeout(() => {
        this.getUsers({
          page: this.currentPage,
          keywords: this.searchText,
          role: "users",
          limit: this.perPage,
        });
      }, 100);
    },

    showItem(row) {
      this.openModal(row, "Show");
    },

    showOrders(row) {
      this.openModal(row, "Orders");
    },

    editItem(row) {
      this.openModal(row, "Edit");
    },

    deleteItem(row) {
      this.confirmDelete(this.deleteBanner, row);
    },
  },

  computed: {
    ...mapGetters(["users", "getLoading"]),
    params() {
      return {
        page: this.currentPage,
        keywords: this.searchText,
        role: "users",
        limit: this.perPage,
      };
    },
  },
  setup(props) {
    return {
      avatarText,
    };
  },
};
</script>