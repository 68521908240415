export default [
  // {
  //   title: 'Dashboard',
  //   // tag: 'Soon',
  //   // tagVariant: 'light-primary',
  //   route: 'Home',
  //   icon: 'HomeIcon',
  // },
  // {
  //   title: 'Sales',
  //   route: 'Orders',
  //   icon: 'ClipboardIcon',
  //   children: [
  //     {
  //       title: 'Orders',
  //       route: 'Orders',
  //       // icon: 'FileIcon',
  //     },
  //     {
  //       title: 'Order Status',
  //       route: 'OrderStatus',
  //       // icon: 'FileIcon',
  //     },
  //     {
  //       title: 'Customers',
  //       // tag: 'Soon',
  //       // tagVariant: 'light-primary',
  //       route: 'Customers',
  //       // icon: 'FileIcon',
  //     },
  //     {
  //       title: 'Offers ',
  //       // tag: 'Soon',
  //       tagVariant: 'light-primary',
  //       route: 'Offers',
  //       // icon: 'FileIcon',
  //     },
  //   ]
  // },
  // {
  //   title: 'Products',
  //   route: 'Products',
  //   icon: 'BoxIcon',
  //   children: [
  //     {
  //       title: 'All Products',
  //       route: 'Products',
  //       icon: 'BoxIcon',
  //     },
  //   ]
  // },
  // {
  //   title: 'Procurement',
  //   route: 'Reciepts',
  //   icon: 'TagIcon',
  //   children: [
  //     {
  //       title: 'Purchasing',
  //       route: 'Reciepts',
  //       // icon: 'FileIcon',
  //     },
  //     {
  //       title: 'Suppliers',
  //       route: 'Suppliers',
  //       // icon: 'FileIcon',
  //     },
  //   ]
  // },
  {
    title: 'Pages',
    icon: "FileIcon",
    children: [
      {
        title: 'Pages',
        route: 'Pages',
        permKeys: ['pages',]
      },
      {
        title: 'Sections',
        route: 'Sections',
        permKeys: ['sections',]
      },

    ],
    permKeys: ['sections', 'pages']
  },
  {
    title: 'Sliders',
    icon: "BoxIcon",

    children: [
      {
        title: 'Sliders',
        route: 'Sliders',
        permKeys: ['sliders']
      },
      {
        title: 'Banners',
        route: 'Banners',
        permKeys: ['banners']
      },
    ],
    permKeys: ['banners', 'sliders']
  },
  {
    title: 'News',
    icon: 'GridIcon',
    children: [
      {
        title: 'All News',
        route: 'News',
        // icon: 'FileIcon',
        permKeys: ['news']
      },
      {
        title: 'Categories',
        route: 'Categories',
        // icon: 'FileIcon',
        permKeys: ['categories']
      },
      // {
      //   title: 'Labels',
      //   route: 'Labels',
      //   // icon: 'FileIcon',
      // },

    ],
    permKeys: ['news', 'categories']
  },
  {
    title: 'Services',
    icon: 'ServerIcon',
    children: [
      {
        title: 'Services List',
        route: 'Services',
        permKeys: ['services']
      },
      {
        title: 'Services Requests',
        route: 'Requests',
        permKeys: ['services_requests']
      },
    ],
    permKeys: ['services_requests', 'services']
  },

  {
    title: 'Contacts',
    route: 'Contacts',
    icon: 'MailIcon',
    children: [
      {
        title: 'Contact Us',
        route: 'ContactUs',
        permKeys: ['contact']
      },
      {
        title: 'Subscribtions',
        route: 'Subscribtions',
        permKeys: ['subscribe']
      },
    ],
    permKeys: ['contact', 'subscribe']
  },
  {
    title: 'Users',
    route: 'Users',
    icon: '',
    permKeys: ['users']
  },
  {
    title: 'Roles',
    route: 'Roles',
    icon: 'LockIcon',
    permKeys: ['roles']
  },
  {
    title: 'Settings',
    route: 'Settings',
    icon: 'SettingsIcon',
    permKeys: ['settings']
  },
];
