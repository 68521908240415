export default {
  data() {
    return {
      afterDot: [

      ]
    }
  },
  methods: {
    checkPermissions(item) {
      if (item.permKeys) {
        let userPerms = this.user.user.permissions;
        if (userPerms.find(el => el == 'admin')) {
          return true
        } else {
          const intersection = userPerms.filter(element => {
            if (element.includes('news') || element.includes('settings')) {
              element = element.split('.')[1]
            }
            return item.permKeys.find(key => element.toLowerCase().includes(key))
          });
          // console.log(intersection);
          if (intersection.length > 0) {
            return true
          } else {
            return false
          }
        }
      } else {
        return true
      }
    },
    resolvePermission(permission) {
      return this.userPermissions.includes(permission) || this.adminPermission
    }
  },
  computed: {
    userPermissions() {
      return this.user.user.permissions;
    },
    adminPermission() {
      return this.user.user.permissions.includes('admin');
    },
    driverPermission() {
      return this.user.user.permissions.includes('driver');
    },
    sellerPermission() {
      return this.user.user.permissions.includes('seller');
    },
  }
}