import Axios from '../../libs/strataIns';

export default {
    state: {
        srRequests: [],
        viwedRequest: null
    },

    getters: {
        srRequests: (state) => state.srRequests,
        viwedRequest: (state) => state.viwedRequest
    },

    mutations: {
        setsrRequests(state, payload) {
            state.srRequests = payload;
        },
        addSrRequest(state, payload) {
            state.srRequests.push(payload);
        },
        updateSrRequest(state, payload) {
            let index = state.srRequests.findIndex(el => el.id == payload.id);
            state.srRequests.splice(index, 1, payload);
        },
        removeSrRequest(state, payload) {
            let index = state.srRequests.findIndex(el => el.id == payload);
            state.srRequests.splice(index, 1);
        },
        viwedRequest(state, payload) {
            state.viwedRequest = payload;
        }

    },
    actions: {
        loadsrRequests({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post("/admin/services_requests").then(res => {
                    if (res.status == 200) {
                        commit("setsrRequests", res.data.results.data);
                        resolve(res);
                    }
                }).catch(rej => {
                    reject(rej);
                });
            });
        },
        loadSrRequest({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/admin/services_requests/show/${payload}`).then(res => {
                    if (res.status == 200) {
                        commit("viwedRequest", res.data.results);
                        resolve(res);
                    }
                }).catch(rej => {
                    reject(rej);
                });
            });
        },
        removeSrRequest({ commit }, payload) {

            return new Promise((resolve, reject) => {
                Axios.post(`/admin/services_requests/delete/${payload}`).then(res => {
                    if (res.status == 200) {
                        commit("removeSrRequest", payload);
                        resolve(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        updateSrRequest({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/admin/services_requests/save`, payload).then(res => {
                    if (res.status == 200) {
                        commit("updateSrRequest", payload);
                        resolve(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        createSrRequest({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/admin/services_requests/save`, { ...payload }).then(res => {
                    if (res.status == 200) {
                        commit("addSrRequest", payload);
                        resolve(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },

    }
};