<template>
  <div v-if="!formLoading && formObj != null">
    <BaseForm
      :editObj="formObj"
      :getBaseData="getBaseData"
      ref="baseForm"
    />
    <ResponsiveFieldsForm
      :defaultItem="defaultItem"
      :editObj="formObj"
      :getRestData="getRestData"
      :formFields="restFields"
      ref="restForm"
    />
  </div>
  <div
    v-else-if="formLoading"
    class="text-center py-5 d-flex align-items-center justify-content-center"
  >
    <b-spinner small class="mx-1" /> {{ $t("Loading...") }}
  </div>
</template>

<script>
import ResponsiveFieldsForm from "./ResponsiveFieldsForm.vue";
import BaseForm from "./BaseForm.vue";
import { BSpinner } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ResponsiveFieldsForm,
    BaseForm,

    BSpinner
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {
        status: true
      },
      baseURL: "",
      defaultItem: {
        status: true,
      },
    };
  },

  props: ["editObj", "closeModal"],
  mounted() {
    for (let i = 0; i <= this.settings.social_media_links.length; i++) {
      if (
        this.settings.social_media_links[i] &&
        this.settings.social_media_links[i].name == "baseurl"
      ) {
        this.baseURL = this.settings.social_media_links[i].link;
        this.$forceUpdate()
      }
    }
    if (this.editObj != null) {
      this.formLoading = true;
      this.getPage(this.editObj.id).then((res) => {
        this.formObj = res.data.results;
        this.formData.id = this.editObj.id;
        setTimeout(() => {
          this.formLoading = false;
        }, 100);
      });
    }else{
      this.formObj = {
        status: true
      };
    }
  },
  methods: {
    ...mapActions(["getPages", "getPage", "createPage"]),
    formSubmit() {
      this.$refs.restForm.submit().then((res) => {
        if (res == true) {
          this.$refs.baseForm.submit().then(baseRes=>{
            if (baseRes == true) {
              this.$store.commit("setModalLoading", true);
              setTimeout(() => {
                this.createPage(this.formData).then((res) => {
                this.closeModal();
                this.getPages({});
                this.$store.commit("setModalLoading", false);
              });
            }, 100);
          }
        })
        }
      });
    },

    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data) {
      this.formData.base64 = [];
      if (this.editObj && this.editObj.image) {
        this.formData.base64 = [
          {
            ...this.editObj.image,
            delete: true,
          },
        ];
      }

      this.formData.base64 = [...this.formData.base64, data];
    },

    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      };
    },
    getRepeaterData(data) {
      this.formData.items = data;
    },
    checkNulldesc() {
      if (
        this.formData.descriptions.ar == null ||
        this.formData.descriptions.en == null
      ) {
        if (this.formData.descriptions.ar == null) {
          this.formData.descriptions.ar = "";
        }
        if (this.formData.descriptions.en == null) {
          this.formData.descriptions.en = "";
        }
      }
    },
  },

  computed: {
    ...mapGetters(["product", "modalLoading","settings"]),
    restFields() {
      return [
        // {name: 'titles', type: 'text', rules: 'required', model: 'titles', },
        {
          name: "path",
          type: "text",
          rules: "required",
          model: "path",
          placeholder: this.baseURL + "/",
          cols: 8,
        },
        {
          name: "ord",
          type: "number",
          rules: "required",
          model: "ord",
          cols: 2,
        },
        {
          name: "status",
          type: "checkbox",
          rules: "required",
          model: "status",
          cols: 2,
        },
      ];
    },
  },
};
</script>