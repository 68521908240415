<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->

      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-0">
        <b-img
          style="height: 100%; width: 100%; object-fit: cover"
          :src="appLoginCover"
          alt="Login V2"
        />
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex auth-bg flex-column px-2 p-lg-5">
        <b-link class="d-flex align-items-center mb-auto">
          <img :src="appLogoImage" width="auto" height="80px" />
          <h2 class="brand-text text-primary ml-1">{{ appName }}</h2>
        </b-link>
        <div class="mb-auto">
          <b-card-title title-tag="h3" class="font-weight-bold mb-1">
            Welcome to {{ appName }}!👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <!-- <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div> -->
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                :disabled="loading"
                variant="primary"
                block
                @click="validationForm"
              >
                {{ loading ? "Loging-in..." : "Sign in" }}
                <b-spinner v-if="loading" small class="mx-1" />
              </b-button>
            </b-form>
          </validation-observer>
        </div>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BSpinner,

    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: false,
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/logo/eg-nisr.png"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      loading: false,
    };
  },
  created() {
    if (this.storageGet("u_eged") != false) {
      this.$router.push({
        name: "Home",
      });
    } else {
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      this.sideImg = require("@/assets/images/sections/embassy-front.jpg");
      // if (store.state.appConfig.layout.skin === "dark") {
      //   this.sideImg = require("@/assets/images/logo/eg-nisr.png");
      //   return this.sideImg;
      // }
      return this.sideImg;
    },
  },
  methods: {
    ...mapActions(["userLogin", "getUserProfile", "getSettings"]),
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        this.submit();
      });
    },
    submit() {
      this.loading = true;
      this.userLogin({
        email: this.userEmail,
        password: this.password,
      })
        .then((res) => {
          if (this.status) {
            this.storageSet("u_eged", res.data.results);
          }
          this.getSettings({})
          this.getUserProfile({});
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Login Successfully",
              icon: "LoginIcon",
              variant: "success",
            },
          });
          this.$router.push({
            name: "Home",
          });
          setTimeout(() => {
            this.loading = false;
          }, 100);
        })
        .catch((err) => {
          this.loading = false;
          this.handleEvent({
            variant: "danger",
            icon: "XIcon",
            msg: err && err.response ? err.response.data.results.message : err,
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
