<template>
  <validation-observer ref="simpleRules" :key="1">
    <b-form @submit.prevent="submit">
      <b-row>
        <!-- name -->
        <b-col cols="12" v-if="!noTitlesRoutes && !descriptionOnly">
          <label v-if="!title" for="title">
            {{ $t("title") }} <span class="text-danger">
              *
            </span>
          </label>
          <label v-else for="title">
            {{ $t(title) }}
          </label>
          <validation-provider
            #default="{ errors }"
            :rules="{
              required: nameLang.active,
            }"
            name="title"
          >
            <b-input-group>
              <b-form-input
                v-if="titleModel"
                id="title"
                :placeholder="nameLang.name == 'ar' ? 'العنوان' : 'title'"
                :class="`text-${
                  nameLang.name == 'ar' && $i18n.locale != 'ar' ? 'right' : ''
                }`"
                v-model="baseData[titleModel][nameLang.name]"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-input
                v-else
                id="title"
                :placeholder="nameLang.name == 'ar' ? 'العنوان' : 'title'"
                :class="`text-${
                  nameLang.name == 'ar' && $i18n.locale != 'ar' ? 'right' : ''
                }`"
                v-model="baseData.titles[nameLang.name]"
                :state="errors.length > 0 ? false : null"
              />
              <!-- @change="checkEmptyLocale" -->
              <b-input-group-append>
                <b-dropdown :text="nameLang.name" variant="outline-primary">
                  <b-dropdown-form v-for="lang in thelangs" :key="lang.name">
                    <div class="d-flex align-items-center pointer">
                      <b-form-checkbox v-model="lang.active" class="mr-50" />
                      <span
                        @click="
                          changeNameLang(lang);
                          $forceUpdate();
                        "
                        class="w-100"
                      >
                        {{ $t(lang.name) }}
                      </span>
                    </div>
                  </b-dropdown-form>
                </b-dropdown>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col class="mt-2" cols="12" v-if="!noDescRoutes && !titleOnly">
          <!-- description  -->
          <b-tabs content-class="mt-0" align="right" class="mt-2 mb-0">
            <b-tab
              :title="$t(lang)"
              active
              v-for="lang in langs"
              :key="lang"
              @click="descLang = lang"
            >
            </b-tab>
          </b-tabs>
          <b-form-group
            :label="$t('Description')"
            label-for="description"
            style="margin-top: -37px"
          >
            <validation-provider
              #default="{ errors }"
              :rules="notReqDesc ? '' : 'required'"
              name="description"
            >
              <quill-editor
                v-if="
                  $route.name == 'Sections' ||
                  $route.name == 'News' ||
                  $route.name == 'Subscribtions' ||
                  $route.name == 'Banners'
                "
                v-model="baseData.descriptions[descLang]"
                placeholder="Description"
                ref="editor"
              />
              <quill-editor
                v-else-if="descModel"
                v-model="baseData[descModel][descLang]"
                placeholder="Description"
              />
              <b-form-textarea
                v-else
                id="description"
                :placeholder="descLang == 'ar' ? 'الوصف' : 'Description'"
                rows="3"
                :class="`text-${
                  $i18n.locale != 'ar' && descLang == 'ar' ? 'right' : ''
                }`"
                v-model="baseData.descriptions[descLang]"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BTabs,
  BTab,
  BDropdown,
  BDropdownItem,
  BDropdownForm,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  password,
} from "@validations";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BTabs,
    BTab,
    BButton,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
  },
  data() {
    return {
      baseData: {
        titles: {
          ar: "",
          en: "",
          de: "",
        },
        descriptions: {
          ar: "",
          en: "",
          de: "",
        },
        tags: {
          ar: "",
          en: "",
          de: "",
        },
        data: {
          ar: "",
          en: "",
          de: "",
        },
        data_title: {
          ar: "",
          en: "",
          de: "",
        },
      },
      nameLang: {
        name: this.$i18n.locale,
        active: true,
      },
      descLang: this.$i18n.locale,
      required,
      password,
      email,
      confirmed,
      thelangs: [
        {
          name: "en",
          active: this.$i18n.locale === "en" ? true : false,
        },
        {
          name: "de",
          active: this.$i18n.locale === "de" ? true : false,
        },
        {
          name: "ar",
          active: this.$i18n.locale === "ar" ? true : false,
        },
      ],
    };
  },
  watch: {
    editObj: function () {
      this.$forceUpdate();
    },
    descLang(nl) {
      if (nl == "ar") {
        this.$refs.editor.quill.scrollingContainer.style.direction = "rtl";
        this.$refs.editor.quill.scrollingContainer.style.textAlign = "right";
      } else {
        this.$refs.editor.quill.scrollingContainer.style.direction = "ltr";
        this.$refs.editor.quill.scrollingContainer.style.textAlign = "left";
      }
    },
  },
  props: [
    "getBaseData",
    "editObj",
    "title",
    "titleOnly",
    "descriptionOnly",
    "titleModel",
    "descModel",
    "notReqDesc",
  ],
  mounted() {
    // var quill = new quillEditor("#editor", {
    //   theme: "snow",
    // });
    if (this.$refs.editor) {
      this.$refs.editor.quill.scrollingContainer.style.direction = "ltr";
      this.$refs.editor.quill.scrollingContainer.style.textAlign = "left";
    }
    if (this.editObj != null) {
      if (this.titleModel == "tags" && this.editObj.titles) {
        this.baseData.tags = { ...this.editObj.tags };
      }
      if (this.descModel == "data" && this.editObj.data) {
        this.baseData.data = { ...this.editObj.data.descriptions };
      }
      if (this.titleModel == "data_title" && this.editObj.data) {
        this.baseData.data_title = { ...this.editObj.data.titles };
      }
      if (this.editObj.titles) {
        this.baseData.titles = { ...this.editObj.titles };
      }
      if (this.editObj.descriptions) {
        this.baseData.descriptions = { ...this.editObj.descriptions };
      }

      for (const key in this.baseData.titles) {
        if (this.baseData.titles[key]) {
          this.thelangs.find((el) => el.name === key).active = true;
        }else{
          this.thelangs.find((el) => el.name === key).active = false;
        }
      }
    }
    this.$forceUpdate();
  },
  methods: {
    submit() {
      return new Promise((resolve, reject) => {
        try {
          this.nameLang =
            this.thelangs.find((el) => {
              return el.active && !this.baseData.titles[el.name] ? true : false;
            }) || this.nameLang;
          this.thelangs.forEach((lang) => {
            if (lang.active === false) {
              this.baseData.titles[lang.name] = null;
            }
          });
          setTimeout(() => {
            this.$refs.simpleRules.validate().then((res) => {
              this.valid = res;
              if (res == true) {
                // this.checkEmptyLocaleDesc(this.descModel);
                this.getBaseData(this.baseData);
                console.log(this.baseData);
                resolve(true);
              } else {
                resolve(false);
              }
            });
          }, 200);
        } catch (err) {
          reject(err);
        }
      });
    },
    changeNameLang(lang) {
      this.nameLang = lang;
    },
    checkEmptyLocale(titleModel) {
      if (this.titleModel && !this.descriptionOnly) {
        for (const [key, value] of Object.entries(this.baseData[titleModel])) {
          if (!value || value == null || value == "") {
            this.baseData[titleModel][key] =
              this.baseData[titleModel][this.nameLang.name];
          }
        }
      } else {
        for (const [key, value] of Object.entries(this.baseData.titles)) {
          if (!value || value == null || value == "") {
            this.baseData.titles[key] =
              this.baseData.titles[this.nameLang.name];
          }
        }
      }
    },
    checkEmptyLocaleDesc(descModel) {
      if (this.descModel && !this.titleOnly) {
        for (const [key, value] of Object.entries(this.baseData[descModel])) {
          if (!value || value == null || value == "") {
            this.baseData[descModel][key] =
              this.baseData[descModel][this.descLang];
          }
        }
      } else {
        for (const [key, value] of Object.entries(this.baseData.descriptions)) {
          if (!value || value == null || value == "") {
            this.baseData.descriptions[key] =
              this.baseData.descriptions[this.descLang];
          }
        }
      }
    },
  },
  computed: {
    noDescRoutes() {
      let routes = ["Labels", "Categories", "JobCategories", "Pages"];
      return routes.includes(this.$route.name);
    },
    noTitlesRoutes() {
      let routes = [];
      return routes.includes(this.$route.name);
    },
  },
  directives: {
    Ripple,
  },
};
</script>
<style>
.nav-tabs {
  margin-bottom: 16px !important;
}
</style>
