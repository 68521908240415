<template>
  <b-card
      no-body
    >
      <b-card-header>
        <b-card-title>
          {{$route.name}}
          <!-- <b-row>
            <b-col cols='10'>
              <b-form-group
                :label="$t('Sort By')"
                label-for="sort"
              >
                <b-form-select
                  id='sort'
                  text-field='title'
                  value-field='key'
                  v-model='sort_by'
                  :options='sortItems'
                  @input="filterItems"
                >

                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols='2' class="align-self-center">
              <b-form-checkbox
                v-model='sort_as'
                value='desc'
                unchecked-value='asc'
                switch
                inline
                class="my-1"
                @input="filterItems"
              >
                {{sort_as == 'asc' ? $t('Ascending') : $t('Descending')}}
              </b-form-checkbox>
            </b-col>
          </b-row> -->
        </b-card-title>
          <div class="">
            <b-button 
              class="mx-2"
              variant='secondary'
              @click='exportData("products/receipts", params)'
            >
            <feather-icon
              icon="DownloadIcon"
              size="15"
            >

            </feather-icon>
              {{$t('Export')}}
            </b-button> 
          <b-button
                class=""
                variant='success'
                @click='openModal(null, "Create")'
              >
              <feather-icon
                icon="PlusIcon"
                size="15"
              >

              </feather-icon>
              {{$t('add new')}}
            </b-button>
          </div>
      </b-card-header>
      <b-form @submit.prevent="filterItems">
        <b-row class="mx-2">
          <b-col>
            <b-form-group
              :label='$t("Search")'
              label-for="basicInput"
            >
              <b-form-input
                id="basicInput"
                v-model='searchText'
              />
            </b-form-group>
          </b-col>
          <!-- <b-col>
            <b-form-group
              label="Basic Input"
              label-for="selectInput"
            >
              <b-form-select
                id='selectInput'
                v-model="selected"
                :options="options"
              />
              <v-select
                v-model="selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="options"
              />
            </b-form-group>
          </b-col> -->
          <b-col>
            <b-form-group
              :label="$t('limit')"
              label-for="perPage"
            >
              <b-form-select    
                id='perPage'
                v-model='perPage'
                :options='perPageOptions'
                @input='changeFocus("submitBtn")'
              ></b-form-select>     
            </b-form-group>
          </b-col>
          <b-col cols='1' class="align-self-center">
            <b-button 
              id='submitBtn'
              variant='primary'
              class="btn my-auto"
              :disabled ='loading'
              type='submit'
            >
              <feather-icon
                icon='SearchIcon'
                size='16'
              ></feather-icon>
            </b-button>
          </b-col>
        </b-row>
      </b-form>

      
    <b-table
      responsive
      :items="reciepts"
      :fields="fields"
      class="mb-0"
    >
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>


      <template #cell(supplier)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.supplier.name)"
              :variant="`light-${resolveRecieptStatus(data.item.status).variant}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.supplier.name }}
          </span>
          <small class="text-muted">{{ data.item.supplier.phone }}</small>
        </b-media>
      </template>


      <template #cell(status)="data">
       
        <b-row >
          <b-col >
              <b-avatar
                :id="`invoice-row-${data.item.id}`"
                size="32"
                :variant="`light-${resolveRecieptStatus(data.item.status).variant}`"
              >
                <feather-icon
                  :icon="resolveRecieptStatus(data.item.status).icon"
                />
              </b-avatar>
              <b-tooltip
                :target="`invoice-row-${data.item.id}`"
                placement="top"
              >
                <p class="mb-0">
                  Status: {{ resolveRecieptStatus(data.item.status).title }}
                  
                </p>
                <!-- <p class="mb-0">
                  Payment Status: {{ data.item.payments_status && data.item.payments_status.paid == true ? 'Paid' : 'Not Paid'}}
                </p>
                <p class="mb-0">
                  Total: {{ data.item.total_net }}
                </p> -->
              </b-tooltip>
          </b-col>
          <!-- <b-col >
            <b-form-select
              :options='ordersStatus'
              text-field="title"
              value-field="id"
              v-model='data.item.status_id'
              @change='saveOrder(data.item)'
            ></b-form-select>
          </b-col> -->
        </b-row>
      </template>
      <template #cell(actions)="row">
          <b-button 
            size="sm" 
            @click="showItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="EyeIcon"
              size="15"
            />
          </b-button>
          <b-button 
            size="sm" 
            @click="editItem(row)"
            variant="flat-dark"  
            class="btn-icon"
            :disabled='row.item.status == "2" || row.item.status == 2'
          >
            <feather-icon
              icon="Edit2Icon"
              size="15"
            />
          </b-button>
          <!-- <b-button 
            size="sm" 
            @click="deleteItem(row)"
            variant="flat-danger"  
            class="btn-icon"
            :disabled='row.item.status == "2" || row.item.status == 2'
          >
            <feather-icon
              icon="Trash2Icon"
              size="15"
            />
          </b-button> -->
          <b-button 
            size="sm" 
            @click="downloadInvoice(row)"
            variant="flat-secondary"  
            class="btn-icon"
          >
            <feather-icon
              icon="DownloadIcon"
              size="15"
            />
          </b-button>
        </template>
    </b-table>

    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @change="filterItems"
        :limt='15'
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import { 
  BTable, BButton, BRow, BCol , 
  BCard, BCardTitle, BCardHeader, BCardFooter, 
  BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BForm,
  BPagination, BTooltip,  BAvatar, BBadge, BMedia
} from 'bootstrap-vue';
import vSelect from 'vue-select'

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { mapActions, mapGetters } from 'vuex';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon, BFormCheckbox, BForm,
    BCard, BCardTitle, BCardHeader, BCardFooter,
    BFormGroup, BFormInput, BFormSelect, vSelect,
    BPagination, BTooltip,  BAvatar, BBadge, BMedia
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: this.$t('ID')
        },
        {
          key: 'supplier',
          label: this.$t('Supplier'),
        },
        {
          key: 'supplier.notes',
          label: this.$t('Notes'),
        },
        {
          key: 'status',
          label: this.$t('status'),
          // formatter()
        },
        
        {
          key: 'value',
          label: this.$t('Unit Price'),
          // formatter()
        },

        // {
        //   key: 'created_at',
        //   label: 'date',
        //   formatter: (value, key, item) => this.formateDate(item),
        // },
        {
          key: 'actions',
          label: this.$t('actions')
        },
      ],
      searchText: '',
      options: [],
      selected: '',
      currentPage: 1,
      perPage: 10,
      rows: 0,
    }
  },
  props: ['openModal', 'closeModal', 'confirmDelete',],
  created() {
    this.getReciepts({})
      .then(res => {
        // console.log(res)
        this.rows = res.data.meta.total
      }).catch(err => console.error(err))
  },

  methods: {
    ...mapActions(['getReciepts', 'deleteCategory', 'createCategory', 'getCategory', 'deleteReciept']),
    filterItems() {
      setTimeout(() => {
        this.getReciepts({
          page: this.currentPage,
          keywords: this.searchText,
          limit: this.perPage
        }).then(res => {
  
        }).catch(err => {
  
        })
      }, 100)
    },

    showItem(row) {
      this.openModal(row, 'Show')
      // console.log(row)
    },
    
    editItem(row) {
      this.openModal(row, 'Edit')
    },

    deleteItem(row) {
      this.confirmDelete(this.deleteReciept, row)
    },
    downloadInvoice(row) {
      this.openModal(row, 'Show');
      let interval = setInterval(() => {
        if(this.loading || this.modalLoading) {

        }else {
          window.print();
          clearInterval(interval)
        }
      }, 300);
    },
  },

  computed: {
    ...mapGetters(['reciepts','loading', 'modalLoading']),
    params() {
      return {
        page: this.currentPage,
        keywords: this.searchText,
        limit: this.perPage
      }
    }
  },

  setup(props) {
    return {
       avatarText,
    }
  }
}
</script>