<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div v-if="profile" class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ profile.name }}
            </p>
            <span class="user-status">{{ $t(profile.permissions[0]) }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="profile&&profile.avatar_url?profile.avatar_url:require('@/assets/images/avatars/download.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item @click="openModal(null,'UpdateAdminProfile',$t('Update profile info'))" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t("Profile") }}</span>
        </b-dropdown-item>
        <b-dropdown-item variant="danger" link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("Logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { mapActions, mapGetters } from "vuex";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Locale,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
    openModal: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    ...mapActions(["userLogout"]),
    logout() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Logout Successful",
          icon: "LogOutIcon",
          variant: "success",
        },
      });
      localStorage.removeItem("u_eged");
      localStorage.removeItem("up_eged");
      this.$router.push({
        name: "Login",
      });
      this.userLogout();
    },
  },
  computed: {
    ...mapGetters(["profile"]),
  },
};
</script>
