<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t($route.name) }}</b-card-title>
      <b-button
        class=""
       variant="primary"
        @click="openModal(null, 'Send')"
        v-if="$route.name == 'Subscribtions'"
      >
        <feather-icon icon="MailIcon" size="15"> </feather-icon>
        {{ $t("Send Mail To Subscribers") }}
      </b-button>
    </b-card-header>
    <b-row class="mx-1">
      <b-col class="col-9 col-md-11">
        <b-form-group :label="$t('Search')" label-for="basicInput">
          <b-form-input id="basicInput" v-model="searchText" />
        </b-form-group>
      </b-col>
      <b-col class="col-3 col-md-1 align-self-center">
        <b-button
          variant="primary"
          class="btn my-auto"
          :disabled="getLoading"
          @click="filterItems"
        >
          <feather-icon icon="SearchIcon" size="16"></feather-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-table
      responsive
      :items="contactRoute ? contactus : subscribtions"
      :fields="contactRoute ? fields : subsFields"
      class="mb-0"
    >
      <template #cell()="data">
        <p class="text-nowrap m-0">
          {{ data.value }}
        </p>
      </template>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          @click="showItem(row)"
          variant="flat-dark"
          class="btn-icon"
          v-if="
            resolvePermission('subscribe.viwe') &&
            $route.name == 'Subscribtions'
          "
        >
          <feather-icon icon="EyeIcon" size="15" />
        </b-button>
        <b-button
          size="sm"
          @click="showItem(row)"
          variant="flat-dark"
          class="btn-icon"
          v-else-if="
            resolvePermission('contact.viwe') && $route.name == 'ContactUs'
          "
        >
          <feather-icon icon="EyeIcon" size="15" />
        </b-button>
      </template>
    </b-table>
    <p
      class="text-center py-4"
      v-if="$route.name == 'ContactUs' && contactus.length == 0"
    >
      {{ $t("no") + $t("msgs") + $t("yet") }}
    </p>
    <p
      class="text-center py-4"
      v-if="$route.name == 'Subscribtions' && subscribtions.length == 0"
    >
      {{ $t("no") + $t($route.name) + $t("yet") }}
    </p>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @change="filterItems"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { mapActions, mapGetters } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPagination,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("ID"),
        },
        {
          key: "name",
          label: this.$t("Name"),
          formatter: (value, ket, item) => value?value.slice(0,25)+(value.length>25?'...':''):'',
        },
        {
          key: "email",
          label: this.$t("Email"),
        },
        {
          key: "subject",
          label: this.$t("Subject"),
          formatter: (value, ket, item) => value?value.slice(0,25)+(value.length>25?'...':''):'',
        },
        {
          key: "description",
          label: this.$t("Description"),
          formatter: (value, ket, item) => value?value.slice(0,25)+(value.length>25?'...':''):'',
        },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ],
      subsFields: [
        {
          key: "id",
          label: this.$t("ID"),
        },
        {
          key: "email",
          label: this.$t("Email"),
        },
        {
          key: "path",
          label: this.$t("Path"),
        },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      searchText: "",
    };
  },
  props: ["openModal", "closeModal", "confirmDelete"],
  watch: {
    $route: function () {
      if (this.contactRoute) {
        this.getContactUs({})
          .then((res) => {
            this.rows = res.data.results.total;
          })
          .catch((err) => console.error(err));
      } else {
        this.getSubscribtions({})
          .then((res) => {
            this.rows = res.data.results.total;
          })
          .catch((err) => console.error(err));
      }
    },
  },
  created() {
    if (this.subscribtionRoute) {
      this.getSubscribtions({})
        .then((res) => {
          this.rows = res.data.results.total;
        })
        .catch((err) => console.error(err));
    } else {
      this.getContactUs({})
        .then((res) => {
          this.rows = res.data.results.total;
        })
        .catch((err) => console.error(err));
    }
  },

  methods: {
    ...mapActions([
      "getContactUs",
      "deleteContactUs",
      "getSubscribtions",
      "deleteSubscribtion",
    ]),
    exportData() {
      alert("export");
    },
    filterItems() {
      if (this.subscribtionRoute) {
        setTimeout(() => {
          this.getSubscribtions({
            page: this.currentPage,
            keywords: this.searchText,
          })
            .then((res) => {
              this.rows = res.data.results.total;
            })
            .catch((err) => {});
        }, 100);
      } else {
        setTimeout(() => {
          this.getContactUs({
            page: this.currentPage,
            keywords: this.searchText,
          })
            .then((res) => {
              this.rows = res.data.results.total;
            })
            .catch((err) => {});
        }, 100);
      }
    },
    showItem(row) {
      this.openModal(row, "Show");
    },

    editItem(row) {
      this.openModal(row, "Edit");
    },

    deleteItem(row) {
      this.contactRoute
        ? this.confirmDelete(this.deleteContactUs, row)
        : this.confirmDelete(this.deleteSubscribtion, row);
    },
  },

  computed: {
    ...mapGetters(["contactus", "subscribtions"]),
    contactRoute() {
      return this.$route.name == "ContactUs";
    },
    subscribtionRoute() {
      return this.$route.name == "Subscribtions";
    },
  },
};
</script>