<template>
  <b-card no-body>
    <b-card-header class="p-1 mx-2">
      <b-card-title>
        <b-row>
          <b-col cols="10">
            <b-form-group :label="$t('Sort By')" label-for="sort">
              <b-form-select
                id="sort"
                text-field="title"
                value-field="key"
                v-model="sort_by"
                :options="sortItems"
                @input="filterItems"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="2" class="align-self-center">
            <b-form-checkbox
              v-model="sort_as"
              value="desc"
              unchecked-value="asc"
              switch
              inline
              class="my-1"
              @input="filterItems"
            >
              {{ sort_as == "asc" ? $t("Ascending") : $t("Descending") }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-card-title>
      <div class="">
        <b-button
          class=""
          variant="secondary"
          @click="exportData('products', params)"
        >
          <feather-icon icon="DownloadIcon" size="15"> </feather-icon>
          {{ $t("Export") }}
        </b-button>
        <b-button
          class="mx-2"
          variant="secondary"
          @click="openModal(null, 'Import')"
        >
          <feather-icon icon="FileIcon" size="15"> </feather-icon>
          {{ $t("Import") }}
        </b-button>
        <b-button class=""variant="primary" @click="openModal(null, 'Create')">
          <feather-icon icon="PlusIcon" size="15"> </feather-icon>
          {{ $t("add new") }}
        </b-button>
      </div>
    </b-card-header>
    <b-form @submit.prevent="filterItems">
      <b-row class="mx-2">
        <b-col>
          <b-form-group :label="$t('Search')" label-for="basicInput">
            <b-form-input id="basicInput" v-model="searchText" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('Categories')" label-for="selectInput">
            <b-form-select
              id="selectInput"
              v-model="selected"
              :options="categories"
              text-field="title"
              value-field="id"
              @input="changeFocus('submitBtn')"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('limit')" label-for="perPage">
            <b-form-select
              id="perPage"
              v-model="perPage"
              :options="perPageOptions"
              @input="changeFocus('submitBtn')"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="align-self-center">
          <b-button
            id="submitBtn"
            variant="primary"
            class="btn my-auto w-100"
            :disabled="loading"
            type="submit"
          >
            <feather-icon icon="SearchIcon" size="16"></feather-icon>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-table responsive :items="products" :fields="fields" class="mb-0">
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>

      <template #cell(image)="data">
        <img
          :src="
            data.item.image && data.item.image.url
              ? data.item.image.url
              : placeHolder
          "
          width="40"
          height="38"
        />
      </template>

      <template #cell(quantity)="data">
        <!-- <b-form-input
          type='number'
          v-model='data.item.items[0].quantity'
          @change='saveProduct(data.item)'
        ></b-form-input> -->
        {{ data.item.items[0].quantity }}
      </template>
      <template #cell(category)="data">
        <b-badge
          pill
          variant="light-primary"
          v-for="cat in data.item.categorys_id"
          :key="cat"
          class="mr-1"
        >
          {{ getCategoryName(cat) }}
        </b-badge>
      </template>
      <template #cell(price)="data">
        <b-form-input
          v-if="editRow == data.index"
          type="number"
          v-model="data.item.items[0].price"
          @change="saveProduct(data.item)"
        ></b-form-input>
        <div class="" v-else>
          <span class="mx-1"> {{ data.item.items[0].price }} </span>
          <!-- <b-button
            variant="flat-info"
            class="btn-icon"
            @click='enableEditRow(data)'
          >

            <feather-icon icon="Edit3Icon" />
          </b-button> -->
        </div>
      </template>

      <template #cell(status)="data">
        <b-avatar
          :id="`invoice-row-${data.item.status}`"
          size="32"
          :variant="`light-${data.item.status ? 'success' : 'danger'}`"
        >
          <feather-icon :icon="data.item.status ? 'CheckIcon' : 'XIcon'" />
        </b-avatar>
      </template>

      <template #cell(actions)="row">
        <!-- <b-button 
            size="sm" 
            @click="showItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="EyeIcon"
              size="15"
            />
          </b-button> -->
        <b-button
          size="sm"
          @click="editItem(row)"
          variant="flat-dark"
          class="btn-icon"
        >
          <feather-icon icon="Edit2Icon" size="15" />
        </b-button>
        <!-- <b-button 
            size="sm" 
            @click="deleteItem(row)"
            variant="flat-danger"  
            class="btn-icon"
          >
            <feather-icon
              icon="Trash2Icon"
              size="15"
            />
          </b-button> -->
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        first-number
        last-number
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @change="filterItems"
        :limit="15"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BAvatar,
  BBadge,
  BFormCheckbox,
  BPagination,
  BRow,
  BCol,
  BForm,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { mapActions, mapGetters } from "vuex";
import XLSX from "xlsx";
import categories from "@/store/categories/categories";

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BBadge,
    BPagination,
    BRow,
    BCol,
    BAvatar,
    BFormCheckbox,
    BForm,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("ID"),
        },
        {
          key: "image",
          label: "",
        },
        {
          key: "title",
          label: this.$t("title"),
        },
        {
          key: "status",
          label: this.$t("status"),
        },
        {
          key: "category",
          label: this.$t("category"),
        },
        // {
        //   key: 'description',
        //   label: 'Description',
        //   formatter: (value, key, item) => value == 'null' ? '-' : value,
        // },
        // {
        //   key: 'created_at',
        //   label: 'date',
        //   formatter: (value, key, item) => this.formateDate(item),
        // },
        {
          key: "items[0].id",
          label: this.$t("code"),
        },
        {
          key: "quantity",
          label: this.$t("quantity"),
        },
        {
          key: "price",
          label: this.$t("price"),
        },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ],
      editRow: null,
      currentPage: 1,
      perPage: 10,
      rows: 0,
      selected: "",
      searchText: "",
      childCategories: [],
      downloadFile: "",
      loaded_cats: [],
      sort_by: "created_at",
      sort_as: "desc",
    };
  },
  props: ["openModal", "closeModal", "confirmDelete"],
  created() {
    this.getProducts({
      load_categories: true,
    })
      .then((res) => {
        this.loaded_cats = res.data.categories;
        this.rows = res.data.meta.total;
      })
      .catch((err) => console.error(err));
    this.getCategories({}).then((res) => {
      // this.childCategories = this.getChildCategories(res.data.results);
    });
  },

  methods: {
    ...mapActions([
      "getProducts",
      "deleteProduct",
      "getCategories",
      "createProduct",
      "getProduct",
    ]),
    filterItems() {
      setTimeout(() => {
        this.getProducts({
          page: this.currentPage,
          keywords: this.searchText,
          category_id: this.selected,
          sort_by: this.sort_by,
          sort_as: this.sort_as,
          limit: this.perPage,
        })
          .then((res) => {})
          .catch((err) => {});
      }, 100);
    },
    showItem(row) {
      this.openModal(row, "Show");
    },

    editItem(row) {
      this.openModal(row, "Edit");
    },

    deleteItem(row) {
      this.confirmDelete(this.deleteProduct, row);
    },

    saveProduct(item) {
      this.$store.commit("setModalLoading", true);
      this.getProduct(item.id).then((res) => {
        let prod = res.data.results;
        if (prod.descriptions.ar == null || prod.descriptions.en == null) {
          if (prod.descriptions.ar == null) {
            prod.descriptions.ar = "";
          }
          if (prod.descriptions.en == null) {
            prod.descriptions.en = "";
          }
        }
        this.createProduct({
          id: prod.id,
          titles: prod.titles,
          descriptions: prod.descriptions,
          status: prod.status,
          featured: prod.featured,
          categorys_id: prod.categorys_id,
          items: item.items,
        }).then((res) => {
          this.getProducts({});
          this.$store.commit("setModalLoading", false);
        });
      });
    },

    enableEditRow(data) {
      this.editRow = data.index;
    },
    getCategoryName(id) {
      if (this.allCategories[id]) {
        return this.allCategories[id].title;
      } else return " - ";
    },
  },

  computed: {
    ...mapGetters(["products", "categories", "user"]),
    params() {
      return {
        page: this.currentPage,
        keywords: this.searchText,
        category_id: this.selected,
        sort_by: this.sort_by,
        sort_as: this.sort_as,
        limit: this.perPage,
      };
    },
    allCategories() {
      return this.loaded_cats;
    },
    sortItems() {
      return [
        { id: 1, title: this.$t("Date Created"), key: "created_at" },
        { id: 2, title: this.$t("title"), key: "title" },
      ];
    },
  },
};
</script>