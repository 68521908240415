import Axios from '../../libs/strataIns';

export default {
  state: {
    solutions: [],
    solution: {},
  },

  getters: {
    solutions: (state) => state.solutions,
    solution: (state) => state.solution,
  },

  mutations: {
    setSolutions(state, payload) {
      state.solutions = [...payload]
    },
    setSolution(state, payload) {
      state.solution = { ...payload }
    },
    removeSolution(state, payload) {
      let index = state.solutions.findIndex(el => el.id == payload);
      state.solutions.splice(index, 1);
    }
  },

  actions: {
    getSolutions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/solutions`, {
          keywords: payload.keywords,
          locale: payload.locale,
          page: payload.page,
          stauts: payload.stauts,
        }, {
        })
          .then(res => {
            if (res.data.results != null) {
              commit('setSolutions', res.data.results);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    getSolution({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/solutions/show/${payload}`, {}, {
        })
          .then(res => {
            if (res.data.results != null) {
              commit('setSolution', res.data.results);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    createSolution(_cont, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/solutions/save`, {
          id: payload.id,
          title: {
            ar: payload.titles.ar,
            en: payload.titles.ar,
          },
          description: {
            ar: payload.descriptions.ar,
            en: payload.descriptions.ar,
          },
          postion: {
            top: payload.top.toString(),
            left: payload.left.toString(),
          },
          category: payload.category,
          details_url: payload.details_url.toString(),
          video_url: payload.video_url,
          status: payload.status,
          images: payload.base64
        })
          .then(res => {
            if (res.data.results != null) {
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    deleteSolutions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/solutions/delete/${payload}`, {})
          .then(res => {
            if (res.data.results != null) {
              commit('removeSolution', res.data.results);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
  }
}