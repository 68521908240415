<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent="submit">
      <b-row>
        <!-- name -->
        <template v-for="(field, fIndex) in formFields">
          <b-col
            :key="fIndex"
            :cols="field.cols == 'auto' ? '' : field.cols || 6"
            :lg="field.lg"
            class="align-self-center"
            :class="field.type == 'groupTitle' ? 'mb-0' : ''"
            v-if="!field.remove"
          >
            <div>
              <h5
                class="mb-2"
                v-if="largeLabels && !['localizedHTML', 'checkbox'].includes(field.type)"
              >
                {{ $t(field.name) }}
                <span
                  v-if="field.rules && field.rules.includes('required')"
                  class="text-danger"
                >
                  *
                </span>
              </h5>
              <b-form-group
                :class="field.type == 'groupTitle' ? 'mb-25' : ''"
                :label-for="field.name + field.rules == 'required' ? ' *' : ''"
                :label-cols="field.labelCols"
              >
                <template #label>
                  <div
                    v-if="
                      !largeLabels &&
                      ![
                        'fromTo',
                        'groupTitle',
                        'localizedTextarea',
                        'localizedHTML',
                        'checkbox',
                      ].includes(field.type)
                    "
                  >
                    {{ $t(field.name) }}
                    <span class="text-danger">{{
                      field.rules == "required" ? " *" : ""
                    }}</span>
                  </div>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :rules="`${field.rules}`"
                  :name="$t(field.name)"
                >
                  <b-input-group v-if="field.type == 'localizedText'">
                    <b-form-input
                      :id="field.name"
                      :placeholder="$t(formData[field.model].locale)"
                      v-model="formData[field.model][formData[field.model].locale]"
                      :state="errors.length > 0 ? false : null"
                      @change="checkEmptyLocale(field.model)"
                      @input="
                        (text) => {
                          field.input ? field.input(text) : false;
                        }
                      "
                      :disabled="field.disabled"
                    />
                    <b-input-group-append>
                      <b-dropdown
                        :text="formData[field.model].locale"
                        variant="outline-primary"
                      >
                        <b-dropdown-item
                          v-for="lang in langs"
                          :key="lang"
                          @click="changeInputLocale(field.model, lang)"
                        >
                          {{ $t(lang) }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-input-group-append>
                  </b-input-group>
                  <div v-else-if="field.type == 'localizedHTML'">
                    <div class="d-flex align-items-center justify-content-between">
                      <label for="textEditorVue" style="font-size: 14px">
                        {{ $t(field.name) }}
                        <span
                          v-if="field.rules && field.rules.includes('required')"
                          class="text-danger"
                          >*</span
                        >
                      </label>
                      <b-tabs content-class="m-0" align="right" class="mb-0">
                        <b-tab
                          :title="$t(lang)"
                          active
                          v-for="lang in langs"
                          :key="lang"
                          @click="changeInputLocale(field.model, lang)"
                        >
                        </b-tab>
                      </b-tabs>
                    </div>
                    <quill-editor
                      v-model="formData[field.model][formData[field.model].locale]"
                      placeholder="Description"
                      @change="checkEmptyLocale(field.model)"
                      ref="editor"
                      id="textEditorVue"
                    />
                  </div>

                  <b-row v-else-if="field.type == 'fromTo'">
                    <b-col cols="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-size="lg"
                        :label="field.name"
                        :label-for="field.name + 'from'"
                      >
                        <!-- <span class="divider"></span> -->
                        <b-form-input
                          size="sm"
                          style="margin-top: 3px"
                          v-model="formData[field.model].from"
                          :id="field.name + 'from'"
                          :state="errors.length > 0 ? false : null"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="5">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-size="lg"
                        :label="$t('To')"
                        :label-for="field.name + 'to'"
                      >
                        <b-form-input
                          size="sm"
                          style="margin-top: 3px"
                          v-model="formData[field.model].to"
                          :id="field.name + 'to'"
                          autocomplete="new-password"
                          :state="errors.length > 0 ? false : null"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="1">
                      <b-button size="sm" variant="outline-primary" class="btn-icon">
                        <mdicon name="autorenew" size="18" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-input-group v-else-if="field.type == 'password'">
                    <b-form-input
                      id="basic-password"
                      autocomplete="new-password"
                      :ref="field.name"
                      :name="field.name"
                      :data-vv-as="field.name"
                      :type="passwordFieldType"
                      v-model="formData[field.model]"
                      placeholder="Your Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <div
                    v-else-if="field.type == 'fileUpload'"
                    class="d-flex draging-area"
                    :class="[field.multiple ? 'flex-column' : '', field.mainClass]"
                    :id="`dragingAreaNo${fIndex}`"
                    @drag="drag($event, fIndex)"
                    @drop="drop($event, fIndex, field)"
                    @dragover="allowDrop($event, fIndex)"
                    @dragleave="removeClass($event, fIndex)"
                  >
                    <div class="file-wrapper position-relative">
                      <b-form-file
                        :class="[
                          errors.length > 0 ? 'error' : '',
                          $i18n.locale,
                          field.multiple ? 'full' : '',
                          field.fileClass,
                        ]"
                        :file-name-formatter="formatNames"
                        ref="fileInput"
                        class="w-100"
                        v-model="formData[field.model]"
                        :multiple="field.multiple"
                        :accept="field.accept"
                        @change="getPreview($event, field.imgPrev, field.multiple, field)"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="field.thePlaceholder"
                      >
                      </b-form-file>
                      <div
                        class="loader position-absolute"
                        style="
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                          z-index: 10;
                          background-color: #fff;
                          padding: 10px;
                          border-radius: 5px;
                          width: 80%;
                          height: 90%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                        v-if="field.loader"
                      >
                        <b-spinner variant="primary" small />
                      </div>
                    </div>
                    <div
                      v-if="formData[field.imgPrev] || formData[field.exImgPrev]"
                      class="shadow-off d-flex align-items-start flex-row flex-wrap justify-content-start w-100"
                    >
                      <div
                        class="d-flex"
                        v-if="
                          !field.multiple &&
                          (formData[field.imgPrev] || formData[field.exImgPrev])
                        "
                      >
                        <div
                          class="rounded images-preview d-flex align-items-center justify-content-center no-multi"
                          v-if="notRemoving"
                        >
                          <img
                            :src="formData[field.imgPrev] || formData[field.exImgPrev]"
                            alt="prevImage"
                            v-if="
                              imgFileChecker(formData[field.imgPrev + '_base64']) ||
                              imgFileChecker(formData[field.exImgPrev])
                            "
                          />
                          <span class="d-flex flex-column align-items-center" v-else>
                            <FeatherIcon icon="FileTextIcon" size="35" />
                            <p class="m-0 mt-1 text-center">
                              {{
                                formData[field.imgPrev + "_fileName"]
                                  ? formData[field.imgPrev + "_fileName"]
                                      .split(".")[0]
                                      .slice(0, 10) +
                                    "..." +
                                    formData[field.imgPrev + "_fileName"].split(".")[
                                      formData[field.imgPrev + "_fileName"].split(".")
                                        .length - 1
                                    ]
                                  : ""
                              }}
                            </p>
                            <p class="m-0 text-center">
                              {{
                                formData[field.imgPrev + "_base64"]
                                  ? getMbFromBytes(
                                      formData[field.imgPrev + "_base64"].size
                                    )
                                  : ""
                              }}
                            </p>
                          </span>
                          <div class="actions">
                            <b-button
                              variant="outline-danger"
                              v-if="
                                !formData[field.exImgPrev] ||
                                (formData[field.exImgPrev] && formData[field.imgPrev]) ||
                                field.deleter
                              "
                              @click="
                                field.deleter
                                  ? field.deleter(true)
                                  : removeImage(formData[field.imgPrev], field, 0)
                              "
                              class="remover btn-icon"
                            >
                              <feather-icon
                                :icon="field.deleter ? 'TrashIcon' : 'XIcon'"
                              ></feather-icon>
                            </b-button>
                            <b-button
                              variant="outline-light"
                              v-if="formData[field.imgPrev] || formData[field.exImgPrev]"
                              @click="
                                openPreview(
                                  formData[field.imgPrev + '_base64'] ||
                                    formData[field.exImgPrev],
                                  formData[field.imgPrev + '_base64']
                                    ? formData[field.imgPrev + '_base64'].ext
                                    : formData[field.exImgPrev]
                                )
                              "
                              class="viewr btn-icon"
                            >
                              <feather-icon icon="EyeIcon"></feather-icon>
                            </b-button>
                            <b-button
                              variant="outline-light"
                              v-if="formData[field.exImgPrev]"
                              @click="downloadFileFromUrl(formData[field.exImgPrev])"
                              class="downloader btn-icon"
                            >
                              <feather-icon icon="DownloadIcon"></feather-icon>
                            </b-button>
                          </div>
                        </div>
                        <!-- <b-form-file :class="[errors.length > 0 ? 'error' : '',$i18n.locale]"
                        :file-name-formatter="formatNames" ref="fileInput" class="w-100" v-model="formData[field.model]"
                        :multiple="field.multiple" :accept="field.accept"
                        @change="getPreview($event, field.imgPrev, field.multiple)"
                        :state="errors.length > 0 ? false : null" :placeholder="field.thePlaceholder"></b-form-file> -->
                      </div>
                      <template v-if="field.multiple">
                        <div
                          class="rounded images-preview d-flex align-items-center justify-content-center position-relative"
                          :class="imgFileChecker(imgPr) ? '' : 'notimg'"
                          v-for="(imgPr, indImg) in imgsPrevsList"
                          :key="indImg"
                        >
                          <img
                            v-if="imgFileChecker(imgPr)"
                            :src="imgPr.file"
                            alt="preview"
                          />
                          <span v-else class="d-flex flex-column align-items-center">
                            <mdicon
                              v-if="videoFileChecker(imgPr)"
                              name="video-box"
                              size="35"
                            />
                            <FeatherIcon v-else icon="FileTextIcon" size="35" />
                            <p class="m-0 text-center">
                              {{
                                imgPr.name
                                  ? imgPr.name.split(".")[0].slice(0, 10) +
                                    "..." +
                                    imgPr.name.split(".")[
                                      imgPr.name.split(".").length - 1
                                    ]
                                  : ""
                              }}
                            </p>
                            <p class="m-0 text-center">
                              {{ imgPr.size ? getMbFromBytes(imgPr.size) : "" }}
                            </p>
                          </span>
                          <div class="actions">
                            <b-button
                              :variant="
                                imgPr.ready_to_delete ? 'outline-light' : 'outline-danger'
                              "
                              @click="
                                imgPr.id && field.deleter
                                  ? field.deleter(imgPr.id, imgsPrevsList, indImg, imgPr)
                                  : removeImage(imgPr, field, indImg)
                              "
                              class="remover btn-icon"
                            >
                              <feather-icon
                                :icon="
                                  imgPr.ready_to_delete ? 'CornerUpLeftIcon' : 'TrashIcon'
                                "
                              ></feather-icon>
                            </b-button>
                            <b-button
                              variant="outline-light"
                              @click="openPreview(imgPr)"
                              class="viewr btn-icon"
                            >
                              <feather-icon icon="EyeIcon"></feather-icon>
                            </b-button>
                          </div>
                        </div>
                        <!-- Outer Images Preview -->
                        <hr class="w-100" v-show="outerImgsPrevsList.length > 0" />
                        <div
                          class="rounded images-preview d-flex align-items-center justify-content-center"
                          :class="imgFileChecker(imgPr) ? '' : 'notimg'"
                          v-show="outerImgsPrevsList.length > 0"
                          v-for="(imgPr, indImg) in outerImgsPrevsList"
                          :key="imgPr.id"
                        >
                          <img
                            v-if="imgFileChecker(imgPr)"
                            :src="imgPr.file"
                            alt="preview"
                          />
                          <span v-else class="d-flex flex-column align-items-center">
                            <mdicon
                              v-if="videoFileChecker(imgPr)"
                              name="video-box"
                              size="35"
                            />
                            <FeatherIcon v-else icon="FileTextIcon" size="35" />
                            <p class="m-0 text-center">
                              {{
                                imgPr.name
                                  ? imgPr.name.split(".")[0].slice(0, 10) +
                                    "..." +
                                    imgPr.name.split(".")[
                                      imgPr.name.split(".").length - 1
                                    ]
                                  : ""
                              }}
                            </p>
                            <p class="m-0 text-center">
                              {{ imgPr.size ? getMbFromBytes(imgPr.size) : "" }}
                            </p>
                          </span>
                          <div class="actions">
                            <b-button
                              :variant="
                                imgPr.ready_to_delete ? 'outline-light' : 'outline-danger'
                              "
                              @click="
                                imgPr.id && field.deleter
                                  ? field.deleter(
                                      imgPr.id,
                                      outerImgsPrevsList,
                                      indImg,
                                      imgPr
                                    )
                                  : removeImage(imgPr, field, indImg)
                              "
                              class="deleter btn-icon"
                            >
                              <feather-icon
                                :icon="
                                  imgPr.ready_to_delete ? 'CornerUpLeftIcon' : 'TrashIcon'
                                "
                              ></feather-icon>
                            </b-button>
                            <b-button
                              variant="outline-light"
                              @click="downloadFileFromUrl(imgPr.file)"
                              class="downloader btn-icon"
                            >
                              <feather-icon icon="DownloadIcon"></feather-icon>
                            </b-button>
                            <b-button
                              variant="outline-light"
                              @click="openPreview(imgPr)"
                              class="viewr btn-icon"
                            >
                              <feather-icon icon="EyeIcon"></feather-icon>
                            </b-button>
                          </div>
                        </div>
                        <!-- <b-form-file :class="[errors.length > 0 ? 'error' : '',$i18n.locale]"
                        :file-name-formatter="formatNames" ref="fileInput" class="w-100" v-model="formData[field.model]"
                        :multiple="field.multiple" :accept="field.accept"
                        @change="getPreview($event, field.imgPrev, field.multiple)"
                        :state="errors.length > 0 ? false : null" :placeholder="field.thePlaceholder"></b-form-file> -->
                      </template>
                    </div>
                  </div>
                  <div v-else-if="field.type == 'localizedTextarea'">
                    <div class="d-flex justify-content-between align-items-end">
                      <label :for="field.model">
                        {{ $t(field.name) }}
                      </label>
                      <b-dropdown
                        size="sm"
                        variant="flat-primary"
                        content-class="m-0"
                        align="right"
                        class="m-0 locales"
                        :text="$t(formData[field.model].locale)"
                      >
                        <b-dropdown-item
                          :title="$t(lang)"
                          variant="outline-primary"
                          v-for="lang in langs"
                          :key="lang"
                          @click="
                            formData[field.model].locale = lang;
                            $forceUpdate();
                          "
                        >
                          {{ $t(lang) }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                    <b-form-textarea
                      @change="checkEmptyLocale(field.model)"
                      rows="3"
                      :state="errors.length > 0 ? false : null"
                      :id="field.model"
                      :placeholder="$t(formData[field.model].locale)"
                      v-model="formData[field.model][formData[field.model].locale]"
                      autocomplete="new-password"
                    >
                    </b-form-textarea>
                  </div>
                  <div v-else-if="field.type === 'image'">
                    <img width="100%" :src="formData[field.model]" v-if="formData[field.model]" />
                    <span class="text-danger" v-else>{{ field.message }}</span>
                  </div>
                  <div v-else-if="field.type == 'textarea'">
                    <b-form-textarea
                      rows="3"
                      :state="errors.length > 0 ? false : null"
                      :id="field.model"
                      v-model="formData[field.model]"
                      autocomplete="new-password"
                    >
                    </b-form-textarea>
                  </div>
                  <v-select
                    v-else-if="field.type == 'searchSelect'"
                    :id="field.name"
                    :options="field.options"
                    :class="errors.length > 0 ? 'error' : ''"
                    :label="field.label"
                    :reduce="
                      field.reducer ? (item) => field.reducer(item) : (item) => item
                    "
                    v-model="formData[field.model]"
                    :disabled="field.disabled"
                    :loading="field.isLoading"
                    @option:selected="
                      (it) => (field.selectItem ? field.selectItem(it) : (it) => it)
                    "
                    @input="
                      (it) => (field.input ? field.input(it) : (it) => it)
                    "
                    @option:deselecting="
                      (t) => (field.deselectItem ? field.deselectItem(t) : t)
                    "
                    @search="
                      (text) => (field.searchItems ? field.searchItems(text) : false)
                    "
                    :multiple="field.multiple"
                    :taggable="field.taggable"
                    :searchable="field.searchable"
                    :placeholder="field.placeholder"
                    :style="
                      $route.name == 'Privileges'
                        ? 'background: white; border-radius: 5px;'
                        : ''
                    "
                  >
                    <template v-if="field.optionTemplate" #option="{ titles, label, id }">
                      <span v-if="field.optionTemplate == 'roles'">
                        {{ titles ? titles[locale] : "" }}
                      </span>
                      <span v-if="field.optionTemplate == 'icons'">
                        <mdicon class="mx-1" v-if="id" :name="id" size="18" />
                        {{ label }}
                      </span>
                    </template>
                    <template
                      v-if="field.optionTemplate"
                      #selected-option="{ titles, label, id }"
                    >
                      <span v-if="field.optionTemplate == 'roles'">
                        {{ titles ? titles[locale] : "" }}
                      </span>
                      <span v-if="field.optionTemplate == 'icons'">
                        <mdicon class="mx-1" v-if="id" :name="id" size="18" />
                        {{ label }}
                      </span>
                    </template>
                    <span slot="no-options">
                      {{ field.noOptions || $t("No Options") }}
                    </span>
                  </v-select>
                  <v-select
                    v-else-if="field.type == 'searchSelectItem'"
                    :id="field.name"
                    :options="field.options"
                    :label="field.label"
                    :reduce="(item) => item"
                    v-model="formData[field.model]"
                    :disabled="field.disabled"
                    @search="field.searchItems"
                    :multiple="field.multiple"
                  >
                  </v-select>
                  <b-input-group
                    v-else-if="field.type == 'select'"
                    :prepend="field.prepend ? field.prepend : ''"
                    :append="field.append ? field.append : ''"
                  >
                    <b-form-select
                      :id="field.name"
                      :options="field.options"
                      :text-field="field.label"
                      value-field="value"
                      v-model="formData[field.model]"
                      :disabled="field.disabled"
                    ></b-form-select>
                  </b-input-group>
                  <b-input-group
                    v-else-if="field.type == 'colorPicker'"
                    :prepend="field.prepend ? field.prepend : ''"
                    :append="field.append ? field.append : ''"
                  >
                     <colorpicker
                      label="Choose Color"
                      :value="formData[field.model] || {}"
                      @input="updateColor($event, field)"
                    />
                  </b-input-group>
                  <b-form-checkbox
                    v-else-if="field.type == 'switch'"
                    v-model="formData[field.model]"
                    @change="(e) => (field.change ? field.change(e) : e)"
                    :id="field.inputId || field.name"
                    switch
                    inline
                  >
                    <!-- {{field.name}} -->
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-else-if="field.type == 'checkbox'"
                    v-model="formData[field.model]"
                    :id="field.name"
                  >
                    {{ field.name }}
                  </b-form-checkbox>
                  <b-form-radio-group
                    v-else-if="field.type == 'radio'"
                    v-model="formData[field.model]"
                    :options="field.options"
                    name="my-1"
                    :id="field.name"
                    small
                  />
                  <h4 v-else-if="field.type == 'groupTitle'" class="mb-0">
                    {{ $t(field.name) }}
                  </h4>
                  <div v-else-if="field.type == 'divider'" class="w-100">
                    <hr class="m-0" />
                  </div>
                  <b-form-file
                    v-else-if="field.type == 'file'"
                    :type="field.type"
                    :id="field.name"
                    v-model="formData[field.model]"
                    :disabled="field.disabled"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-input-group
                    v-else-if="field.type == 'phoneNumber'"
                    :prepend="field.prepend ? field.prepend : ''"
                    :append="field.append ? field.append : ''"
                  >
                    <b-form-input
                      :class="field.type == 'file' ? 'file-uploader-wrapper' : ''"
                      type="number"
                      autocomplete="new-password"
                      @change="validatePhoneNumber($event, field)"
                      :id="field.inputId"
                      :placeholder="field.placeholder"
                      :disabled="field.disabled"
                      v-model="formData[field.model]"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <b-form-input
                    v-else-if="field.type == 'staticURL'"
                    type="text"
                    autocomplete="new-password"
                    :id="field.name"
                    :placeholder="field.placeholder"
                    v-model="formData[field.model]"
                    @change="insertText($event, field)"
                    @focus="clearText(field)"
                    :disabled="field.disabled"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-form-datepicker
                    v-else-if="field.type == 'datePicker'"
                    :placeholder="field.placeholder || '--/--/----'"
                    :id="field.name"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    v-model="formData[field.model]"
                    :state="errors.length > 0 ? false : null"
                    :disabled="field.disabled"
                  />
                  <b-form-timepicker
                    v-else-if="field.type == 'timePicker'"
                    :placeholder="field.placeholder || '--:--'"
                    :id="field.name"
                    v-model="formData[field.model]"
                    :state="errors.length > 0 ? false : null"
                    :disabled="field.disabled"
                  />
                  <b-input-group v-else-if="field.type == 'randomPassword'">
                    <b-form-input
                      type="text"
                      :id="field.name"
                      autocomplete="new-password"
                      :placeholder="field.placeholder"
                      v-model="formData[field.model]"
                      :disabled="field.disabled"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="regeneratePassword(field)"
                      >
                        <mdicon name="sync" size="13" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-input-group v-else :prepend="field.prepend ? field.prepend : ''">
                    <!-- :append="field.append ? field.append : ''" -->

                    <b-form-input
                      type="number"
                      :id="field.name"
                      autocomplete="new-password"
                      v-if="field.type == 'number'"
                      @input="field.searcher ? field.searcher($event) : false"
                      :placeholder="field.placeholder"
                      :disabled="field.disabled"
                      v-model.number="formData[field.model]"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-input
                      v-else
                      :type="field.type"
                      autocomplete="new-password"
                      :id="field.name"
                      @input="field.searcher ? field.searcher($event) : false"
                      :placeholder="field.placeholder"
                      :disabled="field.disabled"
                      v-model="formData[field.model]"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-input-group-append
                      class="pointer red"
                      v-if="field.append && field.model != 'baseurl'"
                      @click="field.appendClick ? field.appendClick(field.model) : false"
                    >
                      <BInputGroupText class="pointer red">
                        {{ field.append }}
                      </BInputGroupText>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
        </template>
        <slot></slot>
      </b-row>
      <div class="image-privewer" v-if="openGlobalPrev">
        <div class="closer d-flex align-items-center" @click="openGlobalPrev = null">
          &times;
        </div>
        <div class="img-container">
          <video
            :src="openGlobalPrev.file"
            v-if="videoFileChecker(openGlobalPrev)"
            style="max-width: 100%"
            width="800px"
            height="auto"
            controls
          ></video>
          <img
            :src="openGlobalPrev.file"
            v-else-if="openGlobalPrev.ext !== 'pdf'"
            :alt="openGlobalPrev.name"
          />
          <embed
            :src="openGlobalPrev.file"
            v-else
            style="min-width: 80vw; min-height: 80vh"
          />
        </div>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
  BFormSelect,
  BFormFile,
  BDropdown,
  BDropdownItem,
  BFormRadio,
  BFormRadioGroup,
  BFormDatepicker,
  BFormTimepicker,
  BSpinner,
  BTab,
  BTabs,
} from "bootstrap-vue";
import intlTelInput from "intl-tel-input";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  alpha,
  integer,
  min,
  digits,
  alphaDash,
  length,
  between,
} from "@validations";
import vSelect from "vue-select";
import "intl-tel-input/build/css/intlTelInput.css";

export default {
  mixins: [togglePasswordVisibility],
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    BTab,
    BTabs,
    BInputGroupText,

    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    vSelect,
    BFormSelect,
    BFormRadio,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BFormTimepicker,
    BSpinner,
  },
  data() {
    return {
      formData: {
        rooms_no: 0,
        adults_no: 0,
      },
      formLoading: false,
      required,
      email,
      confirmed,
      url,
      between,
      url,
      alpha,
      integer,
      min,
      digits,
      alphaDash,
      openGlobalPrev: null,
      length,
      phoneInputIns: [],
      previewLoader: false,
      descLang: this.$i18n.locale,
      imagesPreviews: [],
      titleLocale: this.$i18n.locale,
      theFilesList: [],
      outerImgsPrevsList: [],
      notRemoving: true,
    };
  },
  watch: {
    updateInputs(val) {
      if (val) {
        this.initializeFormData();
      }
    },
    phoneInputIns(n) {
      if (n) {
        const code = n.selectedCountryData.dialCode;
        if (this.formData[n.inputModel] && this.formData[n.inputModel].startsWith(code)) {
          this.formData[n.inputModel] = this.clearPhoneNumber(
            this.formData[n.inputModel]
          );
          this.$forceUpdate();
        }
      }
    },
  },
  props: [
    "defaultItem",
    "getRestData",
    "editObj",
    "formFields",
    "largeLabels",
    "getNewAppName",
    "updateInputs",
  ],
  created() {
    this.formLoading = true;
    if (this.editObj) {
      this.formData = this.editObj;
    } else {
      this.formData = {};
      if(this.defaultItem){
        this.formData = { ...this.defaultItem };
      }
    }
    this.initializeFormData();
    this.formLoading = false;
  },
  methods: {
    videoFileChecker(file) {
      const list = [
        "WEBM",
        "MPG",
        "MP2",
        "MPEG",
        "MPE",
        "MPV",
        "OGG",
        "MP4",
        "M4P",
        "M4V",
        "AVI",
        "WMV",
        "MOV",
        "QT",
        "FLV",
        "SWF",
        "AVCHD",
      ];
      if (file) {
        if (typeof file == "string") {
          const ext = file.split(".")[file.split(".").length - 1];
          return list.includes(ext.toUpperCase());
        } else {
          return list.includes(file.ext.toUpperCase());
        }
      } else {
        return false;
      }
    },
    closePrev(e) {
      if (e.key === "Escape") {
        this.openGlobalPrev = null;
      }
    },
    getPreview(file, input, multiple, field) {
      if (
        multiple &&
        ((file.target && file.target.files && file.target.files.length > 0) ||
          (file.dataTransfer &&
            file.dataTransfer.files &&
            file.dataTransfer.files.length > 0))
      ) {
        field.loader = true;
        const thefiles =
          file.target && file.target.files
            ? [...file.target.files, ...this.imagesPreviews]
            : file.dataTransfer
            ? [...file.dataTransfer.files, ...this.imagesPreviews]
            : [...this.formData[input]];
        thefiles.forEach((file, index) => {
          if (file && !file.file && !file.ext) {
            this.theFilesList.push(file);
            this.getBase64(file).then((res) => {
              this.imagesPreviews.push({
                ...res,
                size: file.size,
                ready_to_delete: false,
              });
              if (index == thefiles.length - 1) {
                field.loader = false;
              }
              this.$forceUpdate();
              this.formData[field.model] = this.theFilesList;
              this.formData[input] = this.imagesPreviews;
            });
          } else {
            field.loader = false;
          }
        });
      } else if (
        (file.target && file.target.files && file.target.files[0]) ||
        (file.dataTransfer && file.dataTransfer.files[0])
      ) {
        field.loader = true;
        const thefile = file.dataTransfer
          ? file.dataTransfer.files[0]
          : file.target.files[0];
        this.getBase64(thefile)
          .then((res) => {
            if (res.file) {
              this.formData[input] = res.file;
              this.formData[input + "_base64"] = { ...res, size: thefile.size };
              this.formData[input + "_fileName"] = res.name;
              this.$forceUpdate();
            } else {
              this.prevImg = null;
              this.formData[input] = null;
              this.formData[input + "_base64"] = null;
              this.formData[input + "_fileName"] = null;
            }
            field.loader = false;
          })
          .catch(() => {
            this.formData[input] = null;
            this.formData[input + "_base64"] = null;
            this.formData[input + "_fileName"] = null;
            field.loader = false;
          });
      }
      this.$forceUpdate();
    },
    removeImage(file, input, index) {
      if (input.multiple) {
        if (this.formData[input.model]) {
          const imgInUploader = this.formData[input.model].findIndex(
            (im) => im.name === file.name
          );
          this.formData[input.model].splice(imgInUploader, 1);
        }
        if (this.formData[input.imgPrev]) {
          const imgInLost = this.formData[input.imgPrev].findIndex(
            (im) => im.name === file.name
          );
          this.formData[input.imgPrev].splice(imgInLost, 1);
        }
      } else {
        this.formData[input.model] = null;
        this.formData[input.imgPrev] = null;
        this.formData[input.model + "_base64"] = null;
        this.formData[input.imgPrev + "_base64"] = null;
        this.formData[input.model + "_fileName"] = null;
        this.formData[input.externalPrev] = null;
      }
      this.$forceUpdate();
    },
    intlTelInput,
    submit() {
      return this.$refs.simpleRules.validate().then((res) => {
        if (res == true) {
          this.finalizeImg().then((imgRes) => {
            // this.finalizeData();
            const count = this.formFields.filter((el) => el.type == "phoneNumber");
            if (count.length > 0) {
              for (let index = 0; index < count.length; index++) {
                const element = this.phoneInputIns[index];
                if (
                  this.formData[element.inputModel] &&
                  !this.formData[element.inputModel].startsWith(
                    element.selectedCountryData.dialCode
                  )
                ) {
                  this.formData[element.inputModel] = `${
                    element.selectedCountryData.dialCode
                  }${this.formData[element.inputModel]}`;
                }
              }
            }
            this.getRestData(this.formData);
          });
          return true;
        } else {
          return false;
        }
      });
    },
    openPreview(file, ext) {
      if (typeof file == "string") {
        this.openGlobalPrev = {
          file: file,
          name: "Preview",
          ext: ext,
        };
      } else {
        this.openGlobalPrev = file;
      }
    },
    changeInputLocale(model, lang) {
      this.formData[model].locale = lang;
      this.$forceUpdate();
    },
    checkEmptyLocale(model) {
      // for (const [key, value] of Object.entries(this.formData[model])) {
      //   if (!value || value == null || value == "") {
      //     this.formData[model][key] = this.formData[model][this.formData[model].locale];
      //   }
      // }
    },
    validatePhoneNumber(e, f) {
      if (e && e.length > 4) {
        const country = this.getFlagFromPhone(e);
        if (country) {
          const items = document.querySelectorAll(".iti__country");
          items.forEach((ele) => {
            if (
              ele.getAttribute("data-dial-code") == country.mobileCode.replace("+", "")
            ) {
              this.phoneInputIns[f.place]._selectListItem(ele);
              this.formData[f.model] = this.formatePhoneNumber(e).split(" ")[1];
            }
          });
        }
      }
    },
    updateColor(val, field) {
      if (val) {
        this.formData[field.model] = val.hex;
      }
    },
    initializeFormData() {
      if (this.formFields.some((el) => el.type == "file")) {
        this.formData.newImages = [];
      }
      this.formFields.forEach((field) => {
        if (field.initialValue && this.editObj[field.model] == null) {
          this.formData[field.model] = field.initialValue;
        }
        if (
          ["localizedText", "localizedTextarea", "localizedHTML"].includes(field.type)
        ) {
          if (this.formData[field.model]) {
            this.formData[field.model].locale = this.$i18n.locale;
          } else {
            this.formData[field.model] = {
              locale: this.$i18n.locale,
            };
          }
          this.langs.forEach((locale) => {
            if (!this.formData[field.model][locale]) {
              this.formData[field.model][locale] = "";
            }
          });
        } else if (field.type == "fileUpload") {
          window.addEventListener("keydown", this.closePrev);
          field.loader = false;
          if (field.multiple) {
            if (this.formData[field.exImgPrev]) {
              let urlKey = "url";
              this.formData[field.exImgPrev].forEach((el) => {
                Object.keys(el).forEach((el) => {
                  if (el.includes("url")) {
                    urlKey = el;
                  }
                });
              });
              const list = this.formData[field.exImgPrev].map((el) => {
                return el && el[urlKey]
                  ? {
                      file: el[urlKey],
                      ext: el[urlKey].split(".")[el[urlKey].split(".").length - 1],
                      name: el.names ? el.names[this.$i18n.locale] : el.name,
                      size: el.descriptions
                        ? el.descriptions[this.$i18n.locale]
                        : el.description,
                      id: el.id,
                      ready_to_delete: false,
                    }
                  : { file: "", ext: "" };
              });
              this.outerImgsPrevsList = this.formData[field.exImgPrev] ? list : [];
            }
          } else {
            this.formData[field.imgPrev] = this.formData[field.exImgPrev] ? null : null;
          }
        } else if (field.type == "phoneNumber") {
          setTimeout(() => {
            const iput = document.getElementById(field.inputId);
            const code = this.getFlagFromPhone(this.formData[field.model]);
            this.formData[field.model] = this.clearPhoneNumber(
              this.formData[field.model]
            );
            const tryFindEleAgain = () => {
              if (iput) {
                this.phoneInputIns[field.place] = this.intlTelInput(iput, {
                  initialCountry: code
                    ? code.code.toLowerCase()
                    : process.env.VUE_APP_variable_name == "sooq"
                    ? "qa"
                    : "eg",
                  preferredCountries: [
                    process.env.VUE_APP_variable_name == "sooq" ? "qa" : "eg",
                    process.env.VUE_APP_variable_name == "sooq" ? "eg" : "qa",
                    "ps",
                    "sy",
                    "tr",
                    "sa",
                  ],
                  excludeCountries: ["il"],
                  autoHideDialCode: false,
                  dropdownOptions: {
                    showDialCodeInSelection: true,
                    showFlags: false,
                  },
                  inputOptions: { autocomplete: "off", showDialCode: true },
                });
                iput.addEventListener("countrychange", (e) => {
                  this.phoneInputIns[field.place].selectedFlag.innerHTML = `
                    <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                    <small >+${
                      this.phoneInputIns[field.place].selectedCountryData.dialCode || "--"
                    }</small>
                    `;
                });
                this.phoneInputIns[field.place].selectedFlag.innerHTML = `
                  <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                  <small >+${
                    this.phoneInputIns[field.place].selectedCountryData.dialCode || "--"
                  }</small>
                  `;
                this.phoneInputIns[field.place].inputModel = field.model;
              } else {
                setTimeout(() => {
                  tryFindEleAgain();
                }, 200);
              }
            };
            tryFindEleAgain();
          }, 200);
        } else if (field.type == "fromTo") {
          if (this.formData[field.model]) {
            this.formData[field.model].from = this.from;
            this.formData[field.model].to = this.to;
          } else {
            this.formData[field.model] = {
              from: "",
              to: "",
            };
          }
        }
      });
    },
    finalizeImg() {
      return new Promise((resolve, reject) => {
        if (this.formFields.find((el) => el.type == "file" && el.encode == "base64")) {
          this.formFields.forEach((field) => {
            if (
              field.type == "file" &&
              field.encode == "base64" &&
              this.formData[field.model]
            ) {
              this.getBase64(this.formData[field.model]).then((res) => {
                this.formData.newImages.push(res);
                resolve(this.formData);
              });
            } else {
              resolve(this.formData);
            }
          });
        } else {
          resolve(this.formData);
        }
      });
    },

    insertText(event, field) {
      if (event.includes(field.staticPreText)) {
        event = event.split("//")[1];
        event = event.split(".")[0];
      }
      this.getNewAppName(event.split(" ").join("_").toLowerCase());
      this.formData[
        field.model
      ] = `${field.staticPreText}${event}${field.staticAppendText}`;
      this.formData[field.model] = this.formData[field.model]
        .split(" ")
        .join("_")
        .toLowerCase();
    },
    clearText(field) {
      if (
        this.formData[field.model] &&
        this.formData[field.model].includes(field.staticPreText)
      ) {
        this.formData[field.model] = this.formData[field.model].split("//")[1];

        this.formData[field.model] = this.formData[field.model].split(".")[0];
      } else {
        this.formData[field.model] = "";
      }
      this.$forceUpdate();
    },
    regeneratePassword(field) {
      this.formData[field.model] = this.generateRandomPassword();
      this.$forceUpdate();
    },
    allowDrop(ev) {
      ev.preventDefault();
      ev.target.classList.add("active");
    },
    removeClass(ev) {
      ev.preventDefault();
      ev.target.classList.remove("active");
    },

    drag(ev, index) {
      ev.dataTransfer.setData("file", ev.target.files[0]);
      document.getElementById(`dragingAreaNo${index}`).classList.remove("active");
    },

    drop(ev, index, field) {
      this.getPreview(ev, field.imgPrev, field.multiple);
      document.querySelector("#dragingAreaNo" + index).classList.remove("active");
      ev.preventDefault();
    },
    formatNames(files) {
      if (files.length === 1 && this.theFilesList.length === 1) {
        return files[0].name.slice(0, 15) + "...";
      }
      return `${this.theFilesList.length} ${this.$t("files selected")}`;
    },
    imgFileChecker(file) {
      const list = [
        "JPEG",
        "JPG",
        "PNG",
        "GIF",
        "TIFF",
        "EPS",
        "RAW",
        "ICO",
        "SVG",
        "WEBP",
      ];
      if (file) {
        if (typeof file == "string") {
          const ext = file.split(".")[file.split(".").length - 1];
          return list.includes(ext.toUpperCase());
        } else {
          return list.includes(file.ext.toUpperCase());
        }
      } else {
        return false;
      }
    },
  },
  computed: {
    imgsPrevsList() {
      return this.imagesPreviews;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    exampleFields() {
      return [
        {
          name: "searchSelect",
          type: "searchSelect",
          rules: "required",
          options: ["items"],
          label: "label",
          model: "selectAttr",
          disabled: false,
          searchItems: (searchText) => searchText,
          reducer: (item) => item.id,
        },
        {
          name: "localizedText",
          type: "localizedText",
          rules: "",
          model: "selectAttr",
        },
        {
          name: "select",
          type: "select",
          rules: "required",
          options: ["items"],
          label: "label",
          model: "selectAttr",
        },
        {
          name: "checkbox",
          type: "checkbox",
          rules: "required",
          model: "selectAttr",
        },
        {
          name: "color",
          type: "color",
          placeholder: "placeholder",
          rules: "required",
          model: "selectAttr",
        },
        {
          name: "phone",
          type: "tel",
          placeholder: "placeholder",
          rules: "required",
          model: "selectAttr",
          vifAttribute: "field.vifAttribute",
          vifValue: "field.vifValue",
        },
        {
          name: "file",
          type: "file",
          rules: "required",
          model: "selectAttr",
        },
        {
          name: "fromTo",
          type: "fromTo",
          rules: "required",
          model: "selectAttr",
        },
        {
          title: "Title Here",
          type: "groupTitle",
        },
        {
          type: "divider",
        },
      ];
    },
  },
};
</script>
<style lang="scss">
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.vs__dropdown-menu {
  position: absolute !important;
}
.custom-file-input:lang(ar) ~ .custom-file-label::after {
  content: "تصفح";
}

.images-preview {
  // border: 1px solid #d8d6de;
  margin: 3px;
  width: 220px;
  height: 200px;
  &.no-multi {
    height: 150px;
  }
  &.notimg {
    padding: 20px 0;
    border: 1px solid gray;
    height: 150px;
  }
  overflow: hidden;
  position: relative;
  border-radius: 15px !important;
  &:hover {
    &::before,
    .actions {
      opacity: 1;
      transition: 0.3s linear;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: 0.3s linear;
  }
  .actions {
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    transition: 0.3s linear;
    z-index: 5;
    opacity: 0;
    cursor: pointer;

    transform: translate(-50%, -50%);
    // background: #fff;
  }
  * {
    img {
      width: 100% !important;
      object-fit: contain;
      border-radius: 15px;
      // object-position: center;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    -o-object-position: center;
  }
}

.v-select {
  background-color: #fff;

  &.error {
    .vs__dropdown-toggle {
      border-color: red !important;
    }

    .vs__actions {
      svg {
        color: red !important;
      }
    }
  }
}

.tabs.locales {
  margin: 0 !important;

  ul {
    margin: 0 !important;
    margin-bottom: 0 !important;
  }
}

.quillWrapper {
  background-color: #fff !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  border: 1px solid #ccc;

  font-family: "Montserrat", "Cairo", Helvetica, Arial, serif;

  * {
    font-family: "Montserrat", "Cairo", Helvetica, Arial, serif;
  }

  .ql-editor {
    min-height: 120px !important;
  }
  .ql-container {
    border: unset !important;
  }

  .ql-editor.ql-blank::before {
    font-style: normal !important;
    font-family: "Montserrat", "Cairo", Helvetica, Arial, serif;
  }

  &.is-dark {
    color: #c9cbd0 !important;
    border-color: #404656 !important;
    background-color: #23293e !important;

    &:focus {
      border-color: #ec7529 !important;
    }

    .ql-editor.ql-blank::before {
      color: #c9cbd0 !important;
      font-style: normal !important;
    }

    .ql-editor {
      color: #fff !important;
    }
  }
}

.path-input {
  * {
    border: unset !important;
    border-radius: unset !important;
  }

  border: 1px solid #d8d6de !important;
  border-radius: 0.367rem !important;
  overflow: hidden !important;

  .edit-btn {
    border-left: 1px solid #d8d6de !important;
    padding: 0 10px !important;
  }

  input {
    padding-left: 3px !important;
  }

  .input-group-prepend {
    .input-group-text {
      padding-right: 2px !important;
    }
  }

  .input-group-append {
    .btn {
      padding: 0 5px !important;
    }
  }

  &.en {
    .edit-btn {
      border-right: 1px solid #d8d6de !important;
      padding: 0 10px !important;
    }
  }
}
.nav-tabs {
  margin-bottom: 0 !important;
}
.iti {
  width: 100% !important;

  input {
    width: 100% !important;
  }
}

.draging-area {
  position: relative;

  &::before {
    content: "Drop the file/s";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #5e0e4b97;
    color: #fff;
    border: 2px dashed #ec7529;
    border-radius: 5px;
    font-size: 18px;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
  }

  &.active {
    &::before {
      display: flex;
    }
  }
}

.iti__selected-flag {
  .iti__flag {
    display: none;
  }
}

.iti--allow-dropdown input {
  padding-left: 60px !important;
}

.rangesliderelement {
  align-items: center;
  justify-content: center;
}

.rangesliderelement .vue-histogram-view {
  height: 0 !important;
  display: none !important;
  opacity: 0 !important;
}
.image-privewer {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 99999;
  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 15px;
    padding: 5px;
    img {
      border-radius: 15px;
      max-width: 1000px;
      max-height: 75vh;
    }
  }
  .closer {
    position: absolute;
    color: #fff;
    font-size: 80px;
    top: 40px;
    left: 40px;
    cursor: pointer;
  }
}
[dir="rtl"] .irs-to,
[dir="rtl"] .irs-from,
[dir="rtl"] .irs-single {
  direction: ltr !important;
}
.irs-grid-pol {
  display: none !important;
}
.irs--round .irs-max,
.irs--round .irs-min {
  top: 40px;
  visibility: visible !important;
  background-color: transparent;
}
</style>
