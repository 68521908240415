var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{key:1,ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('b-row',[(!_vm.noTitlesRoutes && !_vm.descriptionOnly)?_c('b-col',{attrs:{"cols":"12"}},[(!_vm.title)?_c('label',{attrs:{"for":"title"}},[_vm._v(" "+_vm._s(_vm.$t("title"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v(" * ")])]):_c('label',{attrs:{"for":"title"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]),_c('validation-provider',{attrs:{"rules":{
            required: _vm.nameLang.active,
          },"name":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input-group',[(_vm.titleModel)?_c('b-form-input',{class:("text-" + (_vm.nameLang.name == 'ar' && _vm.$i18n.locale != 'ar' ? 'right' : '')),attrs:{"id":"title","placeholder":_vm.nameLang.name == 'ar' ? 'العنوان' : 'title',"state":errors.length > 0 ? false : null},model:{value:(_vm.baseData[_vm.titleModel][_vm.nameLang.name]),callback:function ($$v) {_vm.$set(_vm.baseData[_vm.titleModel], _vm.nameLang.name, $$v)},expression:"baseData[titleModel][nameLang.name]"}}):_c('b-form-input',{class:("text-" + (_vm.nameLang.name == 'ar' && _vm.$i18n.locale != 'ar' ? 'right' : '')),attrs:{"id":"title","placeholder":_vm.nameLang.name == 'ar' ? 'العنوان' : 'title',"state":errors.length > 0 ? false : null},model:{value:(_vm.baseData.titles[_vm.nameLang.name]),callback:function ($$v) {_vm.$set(_vm.baseData.titles, _vm.nameLang.name, $$v)},expression:"baseData.titles[nameLang.name]"}}),_c('b-input-group-append',[_c('b-dropdown',{attrs:{"text":_vm.nameLang.name,"variant":"outline-primary"}},_vm._l((_vm.thelangs),function(lang){return _c('b-dropdown-form',{key:lang.name},[_c('div',{staticClass:"d-flex align-items-center pointer"},[_c('b-form-checkbox',{staticClass:"mr-50",model:{value:(lang.active),callback:function ($$v) {_vm.$set(lang, "active", $$v)},expression:"lang.active"}}),_c('span',{staticClass:"w-100",on:{"click":function($event){_vm.changeNameLang(lang);
                        _vm.$forceUpdate();}}},[_vm._v(" "+_vm._s(_vm.$t(lang.name))+" ")])],1)])}),1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2537728058)})],1):_vm._e(),(!_vm.noDescRoutes && !_vm.titleOnly)?_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('b-tabs',{staticClass:"mt-2 mb-0",attrs:{"content-class":"mt-0","align":"right"}},_vm._l((_vm.langs),function(lang){return _c('b-tab',{key:lang,attrs:{"title":_vm.$t(lang),"active":""},on:{"click":function($event){_vm.descLang = lang}}})}),1),_c('b-form-group',{staticStyle:{"margin-top":"-37px"},attrs:{"label":_vm.$t('Description'),"label-for":"description"}},[_c('validation-provider',{attrs:{"rules":_vm.notReqDesc ? '' : 'required',"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [(
                _vm.$route.name == 'Sections' ||
                _vm.$route.name == 'News' ||
                _vm.$route.name == 'Subscribtions' ||
                _vm.$route.name == 'Banners'
              )?_c('quill-editor',{ref:"editor",attrs:{"placeholder":"Description"},model:{value:(_vm.baseData.descriptions[_vm.descLang]),callback:function ($$v) {_vm.$set(_vm.baseData.descriptions, _vm.descLang, $$v)},expression:"baseData.descriptions[descLang]"}}):(_vm.descModel)?_c('quill-editor',{attrs:{"placeholder":"Description"},model:{value:(_vm.baseData[_vm.descModel][_vm.descLang]),callback:function ($$v) {_vm.$set(_vm.baseData[_vm.descModel], _vm.descLang, $$v)},expression:"baseData[descModel][descLang]"}}):_c('b-form-textarea',{class:("text-" + (_vm.$i18n.locale != 'ar' && _vm.descLang == 'ar' ? 'right' : '')),attrs:{"id":"description","placeholder":_vm.descLang == 'ar' ? 'الوصف' : 'Description',"rows":"3"},model:{value:(_vm.baseData.descriptions[_vm.descLang]),callback:function ($$v) {_vm.$set(_vm.baseData.descriptions, _vm.descLang, $$v)},expression:"baseData.descriptions[descLang]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,48047375)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }